<template lang="pug">
    div#app
        router-view
        template(v-if="!authorized")
            Register(
                v-if="layout==='register'"
                @change="changeLayout"
            )
            AuthForm(
                v-if="layout==='auth'"
                @change="changeLayout"
            )
            Reset(
                v-if="layout==='reset'"
                @change="changeLayout"
            )

            Terms(
                v-if="layout2 === 'terms'"
                @change="changeLayout"
            )
                template(v-slot:title) {{title}}
                template
                    .terms__content(v-html="content")
            MobileSelect(
                v-if="layout==='select'"
                @change="changeLayout"
            )
            TizenModal(
                v-if="layout === 'tizenGuide'"
                @change="changeLayout"
            )
        Icons
</template>
<script>
import Register from "@/components/Register";
import AuthForm from "@/components/AuthForm";
import Reset from "@/components/Reset";
import Terms from "@/components/Terms";
import MobileSelect from "@/components/MobileSelect";
import TizenModal from "@/components/TizenModal";
import Icons from "@/components/Icons";
import { mapActions, mapGetters } from "vuex";
import { addBuying, activateGoal, removeTariffPayload } from "@/helpers/metric";

export default {
  name: "app",

  components: {
    Icons,
    Reset,
    AuthForm,
    Register,
    MobileSelect,
    Terms,
    TizenModal
  },

  data: () => ({
    layout: "",
    layout2: "",
    title: "",
    content: "",
    back: null
  }),

  computed: {
    ...mapGetters(["user", "authorized"])
  },

  methods: {
    ...mapActions(["auth", "logout", "checkAuth"]),

    changeLayout(layout) {
      this.layout = layout;
    }
  },

  created() {
    document.title =
      "Смотреть сериалы онлайн | FLEX | новинки зарубежных сериалов в хорошем (HD) качестве";
    this.$store.dispatch("links/getLinks");
  },

  mounted() {
    if (sessionStorage.getItem("cart.buying")) {
      addBuying({
        tariff: JSON.parse(sessionStorage.getItem("cart.tariff")),
        id: sessionStorage.getItem("cart.buying"),
        coupon: JSON.parse(sessionStorage.getItem("cart.coupon"))
      });
      activateGoal("aktivpaidtarif");
      removeTariffPayload();
    }
    if (this.$route.query.code) {
      this.$axios.defaults.headers.common[
        "Authorization"
      ] = `JWT ${this.$route.query.code}`;
      localStorage.setItem("token", this.$route.query.code);
      this.auth();
      if (this.$route.query.to) {
        sessionStorage.setItem("to.link", this.$route.query.to);
      }
      //this.$router.push({ name: 'home' });
    } else {
      this.$bus.$on("user.auth", type => {
        this.auth(type).then(() => {
          this.$bus.$emit("change.layout", "");
        });
      });

      this.$bus.$on("auth.logout", () => {
        this.logout();
      });
    }

    if (this.$route.query.from === "balancer") {
      sessionStorage.setItem("tariff.trial", "true");
    }
    if (this.$route.query.referer !== undefined) {
      sessionStorage.setItem("referer", this.$route.query.referer);
    }

    this.$bus.$on("change.layout", (layout, url, title) => {
      if (layout === "terms") {
        this.layout2 = layout;
        this.title = title;
        this.$axios.get(url).then(({ data }) => {
          this.content = data.text.replace(/{domain}/g, location.hostname);
        });
      } else {
        this.layout = layout;
      }
    });
    this.$bus.$on("close.terms", () => {
      this.layout2 = "";
    });
  }
};
</script>
<style lang="scss">
@import "assets/scss/base";
</style>
