import { invalidSymbolsRegex } from "@/helpers/regex";

export function getCleanPhoneNumber(phone) {
    phone = phone.length > 10 ? phone.replace(invalidSymbolsRegex, "") : phone;
    return phone.trim();
}

export const getImage = url => {
    const baseUrl = process.env.VUE_APP_API;
    if (url && url.match(baseUrl)) {
        return url;
    } else {
        return baseUrl + url;
    }
};
