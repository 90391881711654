<template>
  <ul class="contact-us__contact-list uk-flex uk-flex-center" v-show="show">
    <li>ООО "ГРУППА ИННОВА"</li>
    <li>
      Адрес: улица Бауманская, д. 33/2, корп./ст. Е 1, кв./оф. I /9, г. Москва
    </li>
    <li>ИНН: 9701125036</li>
    <li>Телефон: <a href="tel:+84951504413"> 8 (495) 150-44-13</a></li>
    <li>Почта: <a href="mailto:hello@itdept.cloud">hello@itdept.cloud</a></li>
  </ul>
</template>

<script>
export default {
  props: ["show"]
};
</script>

<style lang="scss">
.contact-us__contact-list {
  list-style: none;
  padding: 0;
  margin: 40px auto;
  width: auto;
  li {
    padding: 0 15px;
    color: #fff;
    opacity: 0.6;
    text-align: left;
    a:hover {
      text-decoration: underline;
    }
  }
  a {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 15px;
    li {
      padding: 10px 15px;
    }
  }
}
.serials-page__footer .contact-us__contact-list {
  margin-bottom: 0;
}
</style>
