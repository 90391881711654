<template lang="pug">
.uk-container
    .footer
        .footer__item(style="width: 100%")
            .footer__name  Скачать мобильное приложение
            .footer__downloads
                Download(link="https://testflight.apple.com/join/2g7cQfvC" icon="apple" platform="iOS")
                Download(:link="links.links.android_app_link" icon="android" platform="Android")
        .footer__item(style="width: 100%")
            .footer__name  Скачать приложение для ТВ
            .footer__downloads
                Download(link="https://testflight.apple.com/join/2g7cQfvC" icon="apple" platform="Apple TV")
                Download(:link="links.links.android_tv_app_link" icon="android" platform="Android TV")
                Download(link="javascript:;" icon="tizen" title="Скачать файл" platform="Tizen" @click.native="$bus.$emit('change.layout', 'tizenGuide')")

        .footer__faq(v-if="supportIsEnabled")
            .footer__name Поддержка
            a.footer__email(href="mailto:support@kinoflex.tv") support@kinoflex.tv
        .footer__socials(v-if="socialsIsEnabled")
            .footer__name Мы в социальных сетях
            .uk-flex
                a.footer__soc(:href="links.links.vk_group_link" target="_blank")
                    img(src="@/assets/images/svg/vk.svg")
                a.footer__soc(:href="links.links.tg_channel_link" target="_blank")
                    img(src="@/assets/images/svg/telegram.svg")

    .logo.logo--footer.uk-flex.uk-text-center.uk-flex-center
        img(src="@/assets/images/svg/logo-new.svg" alt="" width="155")
</template>

<script>
import Download from "@/components/Download";
import ContactInfo from "@/components/ContactInfo";
import { mapState } from "vuex";
export default {
  name: "v-footer",
  components: { Download, ContactInfo },
  data: () => ({
    supportIsEnabled: false,
    socialsIsEnabled: false
  }),
  computed: mapState(["links"])
};
</script>
