<template>
  <div class="contact-us">
    <div class="contact-us__describe" v-if="describe">
      по возникшим вопросам пишите на
      <a href="mailto:support@kinoflex.tv">support@kinoflex.tv</a>
      <br />
      или в
      <a :href="$store.state.links.links.tg_support_link" target="_blank"
        >телеграм канал</a
      >
    </div>
    <PolicyLinks v-if="policyVisible" />
      <router-link v-if="docs" class="copyright-button" :to="{name: 'Copyright'}">
          Документы правообладателей
      </router-link>
    <div class="social-buttons">
      <template v-for="item in items">
        <a
          v-if="item.to"
          :key="item.icon"
          class="social__image"
          target="_blank"
          :href="item.to"
        >
          <img :src="item.icon" />
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import PolicyLinks from "@/components/policy-links";

export default {
  name: "ContactUs",
  components: { PolicyLinks },
  props: {
    describe: {
      type: Boolean,
      default: true
    },
    policyVisible: {
      type: Boolean,
      default: true
    },
      docs: {
          type: Boolean,
          default: false,
      }
  },
  computed: {
    items() {
      return [
        {
          icon: require("@/assets/images/svg/vk.svg"),
          to: this.$store.state.links.links.vk_group_link
        },
        {
          icon: require("@/assets/images/svg/telegram.svg"),
          to: this.$store.state.links.links.tg_channel_link
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
.contact-us {
    color: white;
    text-align: center;
    font-size: 14px;
    margin-bottom: 40px;
    display: grid;
    justify-content: center;
}
.social-buttons {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.copyright-button {
    padding: 10px 20px;
    margin: 15px 0;
    background-color: #122337;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    width: max-content;
    align-self: center;
}
</style>
