<template lang="pug">
.page.uk-flex.uk-flex-column
    template(v-if="!authorized")
        header.header
            HeaderBase
            More
            HeaderMenu
        main
            Serials
            //ZBanner
            // Advertising
            VoiceActing
            See
            //- Genres
            //- Loading
        footer
            DefaultFooter
            ContactUs
            ContactInfo(show="true")

    template(v-else)
        .tariffs--modal.uk-container
            Tarrifs
</template>
<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import Serials from "@/components/Serials";
import Advertising from "@/components/Advertising";
import See from "@/components/See";
import VoiceActing from "@/components/VoiceActing";
import Genres from "@/components/Genres";
import Loading from "@/components/Loading";
import DefaultFooter from "@/components/Footer";
import More from "@/components/More";
import Tarrifs from "@/components/Tarrifs";
import { mapActions, mapGetters } from "vuex";
import ZBanner from "@/components/ZBanner";
import ContactUs from "@/components/ContactUs";
import ContactInfo from "@/components/ContactInfo";
export default {
  components: {
    ContactUs,
    ContactInfo,
    ZBanner,
    Tarrifs,
    More,
    DefaultFooter,
    Loading,
    Genres,
    VoiceActing,
    HeaderBase,
    HeaderMenu,
    Serials,
    Advertising,
    See
  },

  computed: {
    ...mapGetters(["user", "authorized"])
  },

  methods: {
    ...mapActions(["auth", "logout", "checkAuth"])
  },

  created() {
    document.title =
      "Смотреть сериалы онлайн | FLEX | новинки зарубежных сериалов в хорошем (HD) качестве";
  },

  mounted() {
    this.checkAuth();
  }
};
</script>
