<template lang="pug">
    .auth-page
        header.serials-page__header
            HeaderBase
            HeaderMenu
        main.auth-page__container
            AuthWrapper(hideClose)
                ul.register__through.uk-flex.uk-flex-between.uk-flex-bottom(data-uk-tab v-if="!isDomainKg")
                    li(:class="{'uk-active': isPhoneRegister}")
                        a(@click="isPhoneRegister = true") номер телефона
                    li(:class="{'uk-disabled': lockEmailRegister, 'uk-active': !isPhoneRegister}")
                        a(@click="isPhoneRegister = false") E-mail
                form(@submit.prevent="nextStep(null)")
                    InputField.register__field(
                        v-if="isPhoneRegister"
                        type="tel"
                        icon="tel"
                        placeholder="Номер телефона"
                        v-model="form.phone"
                        :disabled="buttonDisabled"
                        autocomplete="tel"
                    )
                    InputField.register__field(
                        v-else
                        type="email"
                        icon="mail"
                        placeholder="E-mail"
                        v-model="form.email"
                        :disabled="buttonDisabled"
                        autocomplete="email"
                    )
                    Errors.uk-margin-bottom(
                        :errors="errors"
                        v-if="Object.keys(errors).length"
                    )
                    .register__button-container
                        button.register__btn(
                            type="submit"
                            :disabled="buttonDisabled"
                        ) Зарегистрироваться
                        button.register__btn.register__btn--blue(
                            @click="$router.push('login')"
                            :disabled="buttonDisabled"
                        ) Войти
                template(#footer)
                    Agreement


</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import AuthWrapper from "@/views/auth/components/AuthWrapper";
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";
import Agreement from "./components/Agreement";

export default {
  name: "RegisterView",

  components: {
    AuthWrapper,
    HeaderBase,
    HeaderMenu,
    InputField,
    Errors,
    Agreement
  },
  mixins: [validatePassword, RegisterMixins, utils],
  data: () => ({
    lockEmailRegister: false
  })
};
</script>
