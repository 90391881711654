import Vue from "vue";
import Vuex from "vuex";
import axios from "@/mixins/axios";
import pathway from "./pathway.js";
import links from "./footer-links";
import filter from "./filter";
import serial from "./serial";
import actors from "./actors";
import faq from "./faq";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        pathway,
        links,
        filter,
        serial,
        actors,
        faq
    },
    state: {
        user: null,
        authorized: false,
        loginForm: {
            phone: "",
            email: "",
            password: ""
        },
        registerForm: {
            phone: "",
            email: "",
            password: ""
        },
        formState: {
            isPhoneRegister: true,
            repeatTime: 0,
            repeatInterval: null
        }
    },

    mutations: {
        SET_USER: (state, payload) => (state.user = payload),
        SET_AUTHORIZED: (state, payload) => (state.authorized = payload),
        SEND_GTM: (id, type) => {
            try {
                dataLayer.push({
                    pageType: "Home",
                    userID: id,
                    event: type,
                    ecommerce: {
                        add: {
                            products: [
                                {
                                    name: "TVShows",
                                    price: 0
                                }
                            ]
                        }
                    }
                });
            } catch (e) {}
        },
        SET_LOGIN_FORM: (state, payload) => (state.loginForm = payload),
        SET_REGISTER_FORM: (state, payload) => (state.registerForm = payload),
        SET_STATE_FORM: (state, payload) => (state.formState = payload)
    },

    actions: {
        auth: ({ commit }, payload) => {
            return axios
                .get("api/v4/user/")
                .then(({ data }) => {
                    commit("SET_USER", data);

                    if (payload) {
                        commit("SEND_GTM", {
                            id: data.pk,
                            type: payload
                        });
                    }

                    let append = "";
                    try {
                        const urlParams = new URLSearchParams(window.location.search);
                        if (urlParams.has("to")) {
                            append = "?to=" + urlParams.get("to");
                        }
                    } catch (e) {}

                    if (sessionStorage.hasOwnProperty("to.link")) {
                        append = "?to=" + sessionStorage.getItem("to.link");
                        sessionStorage.removeItem("to.link");
                    }

                    const token = localStorage.getItem("token");
                    const refreshToken = localStorage.getItem("refresh_token");

                    sessionStorage.removeItem("user.authorized");
                    localStorage.removeItem("token");
                    localStorage.removeItem("refresh_token");
                    location.href = `/loading/?token=${token}&refresh=${refreshToken}&append=${append}`;

                    sessionStorage.setItem("user.authorized", true);
                    commit("SET_AUTHORIZED", true);
                })
                .catch(() => {
                    commit("SET_AUTHORIZED", false);
                });
        },

        logout: ({ commit }) => {
            commit("SET_USER", null);
            commit("SET_AUTHORIZED", false);
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            sessionStorage.removeItem("user.authorized");
            axios.post("auth/logout/");
        },

        checkAuth: ({ commit, dispatch }) => {
            if (sessionStorage.hasOwnProperty("user.authorized")) {
                commit("SET_AUTHORIZED", true);
                dispatch("auth");
            }
        },

        reFetch: ({ commit }) => {
            axios.get("api/v4/user/").then(({ data }) => {
                commit("SET_USER", data);
            });
        }
    },

    getters: {
        user: state => state.user,
        authorized: state => state.authorized
    }
});
