import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import PaySuccess from "@/views/PaySuccess";
import Complete from "@/views/Complete";
import PayFail from "@/views/PayFail";
import Result from "@/views/Result";
// import Terms from "@/views/Terms";
// import BankingAgreement from "@/views/BankingAgreement";
// import Tariffs from '@/views/Tariffs';
// import Movies from '@/views/movies';
// import Film from '@/views/film/index';
import Register from "@/views/auth/Register";
import RegisterFree from "@/views/auth/RegisterFree";
import RegisterPassword from "@/views/auth/RegisterPassword";
import RegisterConfirm from "@/views/auth/RegisterConfirm";
import Login from "@/views/auth/Login";
import LoginPassword from "@/views/auth/LoginPassword";
import Reset from "@/views/auth/Reset";
import CopyrightDocs from '@/views/policy/CopyrightDocs';
import PolicyPrivate from '@/views/policy/PolicyPrivate.vue';
import Agreement18 from '@/views/policy/Agreement18.vue';
import Store from '@/views/policy/Store.vue';
// import Actors from '@/views/actors';
// import FAQ from "@/views/FAQ";
// import CookiePolicy from "@/views/policy/CookiePolicy";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/pay-success",
            name: "pay-success",
            component: PaySuccess
        },
        {
            path: "/complete",
            name: "complete",
            component: Complete
        },
        {
            path: "/pay-fail",
            name: "pay-fail",
            component: PayFail
        },
        // {
        //     path: "/terms",
        //     name: "terms",
        //     component: Terms
        // },
        // {
        //     path: "/banking-agreement",
        //     name: "BankingAgreement",
        //     component: BankingAgreement
        // },
        {
            path: "*",
            name: "other",
            component: Login,
            meta: {
                isRegistrationFlow: true
            }
        },
        {
            path: "/result",
            name: "result",
            component: Result
        },
        {
            path: "/",
            name: "home",
            component: Login,
            // meta: {
            //     isRegistrationFlow: true
            // }
        },
        // {
        //     path: "/register-free",
        //     name: "RegisterFree",
        //     component: RegisterFree
        // },
        // {
        //     path: "/register/password",
        //     name: "RegisterPassword",
        //     component: RegisterPassword,
        //     meta: {
        //         isRegistrationFlow: true
        //     }
        // },
        // {
        //     path: "/register/confirm",
        //     name: "RegisterConfirm",
        //     component: RegisterConfirm,
        //     meta: {
        //         isRegistrationFlow: true
        //     }
        // },
        // {
        //     path: "/login",
        //     name: "Login",
        //     component: Login
        // },
        // {
        //     path: "/login/password",
        //     name: "LoginPassword",
        //     component: LoginPassword
        // },
        // {
        //     path: "/reset",
        //     name: "Reset",
        //     component: Reset
        // },
        {
            path: '/copyright_docs',
            name: 'Copyright',
            component: CopyrightDocs,
        },
        {
            path: '/policy-private',
            name: 'PolicyPrivate',
            component: PolicyPrivate,
        },
        {
            path: '/agreement-18',
            name: 'Agreement18',
            component: Agreement18,
        },
        {
            path: '/store',
            name: 'Store',
            component: Store,
        }
    // {
    //     path: "/policy-use",
    //     name: "cookie",
    //     component: CookiePolicy,
    // },
    ],

    // Скроллинг к началу страницы после роутинга
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

export default router;
