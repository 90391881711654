<template lang="pug">
    .tariffs
        .tariffs-item
            .tariffs-body(v-if="items.length > 0")
                .tariffs-head.uk-flex.uk-flex-middle.uk-flex-between
                    .title.title--tariffs  Выберите тариф
                    Profile(v-if="user" :user="user")
                .tariffs-body__pane
                    .tariffs-body__container.tariffs-body__container--trial(v-if="trialItems.length")
                        .tariffs-body__tooltip
                            span Ограниченный доступ
                        a.tariffs-body__item(
                            v-for="(item, index) in trialItems"
                            :key="index"
                            :class="{active: item.id === tariff.id}"
                            @click.prevent="selectTariff(item)"
                        )
                            .tariffs-body__label Пробный
                            .tariffs-body__title {{item.days_count}} дней
                            .tariffs-body__price {{item.discounted_price / 100}} ₽
                            span {{((item.discounted_price / 100) / item.days_count).toFixed(2)}} ₽ в день
                    .tariffs-body__container
                        .tariffs-body__tooltip
                            span Полный доступ
                        a.tariffs-body__item(
                            v-for="(item, index) in mainItems"
                            :key="index"
                            :class="{active: item.id === tariff.id}"
                            @click.prevent="selectTariff(item)"
                        )
                            .tariffs-body__badge(v-if="index >= 1") Экономия {{index === 1? '8%' : '25%'}}
                            .tariffs-body__label Полный
                            .tariffs-body__title {{item.days_count}} дней
                            .tariffs-body__price {{item.discounted_price / 100}} ₽
                            span {{((item.discounted_price / 100) / item.days_count).toFixed(2)}} ₽ в день

                .tariffs-bottom
                    .tariffs-bottom__text
                        .uk-flex.uk-flex-middle.uk-margin-small
                            .title.title--days Тариф на {{tariff.days_count}} дней
                            .link.link--tariffs(v-if="tariff.free") Бесплатно
                        .desc.desc--tariffs(v-html="tariff.description")
                    .tariffs-bottom__reciple
                        //.title.title--content Что входит в тариф

                        .tariffs-service
                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text <strong>{{tariff.days_count}} дней</strong> просмотра без рекламы!

                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text Авторская многоголосая озвучка <strong>TVShows</strong>


                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text Разрешение Full HD1080 и объемный звук 5.1

                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text Приложение для IOS, Android, IOS TV, Android TV


                        .tariffs-service
                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text(v-if="tariff.is_trial") Просмотр 1 серии любого сериала
                                .tariffs-service__text(v-else) Доступно для загрузки {{ tariff.downloads_count }} видео

                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text(v-if="tariff.is_trial") Просмотр 1 фильма из подписки
                                .tariffs-service__text(v-else) Неограниченный просмотр сериалов


                            .tariffs-service__image
                            .tariffs-service__data.uk-flex.uk-flex-middle
                                .tariffs-service__text(v-if="tariff.is_trial") Просмотр каталога платных фильмов
                                .tariffs-service__text(v-else) Неограниченный просмотр фильмов из подписки

                            template(v-if="!tariff.is_trial")
                                .tariffs-service__image
                                .tariffs-service__data.uk-flex.uk-flex-middle
                                    .tariffs-service__text Покупка платных фильмов из каталога

                    .tariffs-bottom__form
                        .uk-margin-bottom
                            template(v-if="!tariff.is_trial")
                                .tariffs-content__input.uk-flex.uk-flex-middle.uk-margin-small-bottom(:class="{free: disabledDiscount}")
                                    svg
                                        use(xlink:href="#ticket")
                                    input.input.input--tariffs(
                                        style="width: 100%"
                                        type="send"
                                        placeholder="Введите промокод"
                                        :disabled="disabledDiscount"
                                        v-model="promocode"
                                    )

                                button.button.button--all.button--promo(
                                    type="button"
                                    :disabled="disabledDiscount || disabledDiscount"
                                    @click="loadPromo"
                                ) Применить промокод

                        .tariffs-content__buttons
                            template
                                .title.title--price(:class="{free: tariff.free}") Стоимость тарифа
                                .title.title--price.title--bold.uk-text-right(:class="{free: tariff.free}")  {{tariff.discounted_price / 100}}₽
                                template(v-if="discount > 0")
                                    .title.title--price(:class="{free: tariff.free}") Скидка  - {{discountPercent}}%
                                    .title.title--price.title--bold.uk-text-right(:class="{free: tariff.free}" style="color: #cd025b; white-space: nowrap;")  {{discount}}₽
                                template(v-if="appendDays > 0")
                                    .title.title--price(:class="{free: tariff.free}") Доп. дни
                                    .title.title--price.title--bold.uk-text-right(style="color: #cd025b; white-space: nowrap;") + {{appendDays | plurals(["день", "дня", "дней"])}}

                            ModalTariffPay(:tariff="tariff" :promo="appliedPromo")
                            .title.title--price.title.title--all.uk-text-right(v-if="!tariff.is_trial")  {{price}}₽
                            Errors(:errors="errors" v-if="errors")

                        form(
                            :action="payForm.url"
                            method="POST"
                            v-if="payForm"
                            ref="payForm"
                        )
                            input(
                                type="hidden"
                                v-for="(value, field) in payForm.content"
                                :key="field"
                                :name="field"
                                :value="value"
                            )

</template>

<script>
import Input from "../mixins/input";
import Errors from "@/components/Errors";
import Profile from "@/components/Profile";
import { activateGoal } from "@/helpers/metric";
import { mapGetters } from "vuex";
import ModalTariffPay from "@/components/ModalTariffPay";

export default {
  name: "tarrifs",

  data: () => ({
    items: [],
    tariff: null,
    promocode: "",
    disabled: false,
    promoSum: 0,
    payForm: null,
    errors: null,
    discountPercent: 0,
    discount: 0,
    disabledDiscount: false,
    appendDays: 0,
    appliedPromo: null
  }),

  components: {
    ModalTariffPay,
    Profile,
    Errors,
    Input
  },

  computed: {
    ...mapGetters(["user", "authorized"]),
    tariffPrice: vm => vm.tariff.discounted_price / 100,
    price: vm => vm.tariffPrice - (vm.discount < 0 ? 0 : vm.discount),
    trialItems: vm => vm.items.filter(el => el.is_trial),
    mainItems: vm => vm.items.filter(el => !el.is_trial)
  },

  methods: {
    selectTariff(item) {
      this.tariff = item;
      this.promocode = "";
      this.discountPercent = 0;
      this.discount = 0;
      this.appendDays = 0;
    },

    loadPromo() {
      if (!this.authorized) {
        activateGoal("butregistr");
        this.$router.push({ name: "Register" });
        return;
      }
      this.isPromoRight = false;
      this.disabled = true;
      this.errors = null;

      if (!this.promocode.length) {
        this.errors = ["Вы не ввели промокод"];
        this.disabled = false;
        return;
      }

      this.$axios
        .get(
          `/api/v4/payment/promocode/?name=${this.promocode}&tariff_id=${this.tariff.id}`
        )
        .then(({ data }) => {
          activateGoal("promokod");
          this.disabled = false;
          if (data.discount_money) {
            this.discount = data.discount_money / 100;
            this.discountPercent = Math.ceil(
              (data.discount_money /
                100 /
                (this.tariff.discounted_price / 100)) *
                100
            );
          }
          if (data.discount_procent) {
            this.discountPercent = data.discount_procent;
            this.discount = Math.ceil(
              ((this.tariff.discounted_price / 100) * data.discount_procent) /
                100
            );
          }
          this.appendDays = data.add_days;
          this.appliedPromo = this.promocode;
        })
        .catch(({ response }) => {
          this.errors = response.data;
          this.disabled = false;
        });
    },

    start() {
      this.disabled = true;
      this.errors = null;

      let data = {
        id: this.tariff.id,
        scenario: "fake",
        domain: location.origin + "/",
        force: true,
        reuse_card: false,
        is_reccurent: false
      };

      if (this.tariff.payment.indexOf("fake") === -1) {
        data.scenario = this.tariff.payment[0];
      }

      if (this.appliedPromo !== null) {
        data.coupon = this.appliedPromo;
      }

      this.$axios
        .post("/api/v4/payment/start/", data)
        .then(({ data: { content, url, type, id } }) => {
          if (type === "form") {
            this.payForm = {
              url,
              content
            };
            this.$nextTick(() => {
              this.$refs.payForm.submit();
            });
          } else if (type === "redirect") {
            window.location = url;
            this.disabled = false;
          }
        })
        .catch(({ response }) => {
          this.errors = response.data;
          this.$uikit.modal.alert(
            "Возникла ошибка, попробуйте соврешить оплату позже!"
          );
          this.disabled = false;
        });
    }
  },

  created() {
    this.$axios.get("/api/v4/tarrifs/").then(({ data }) => {
      this.items = data.results;

      if (sessionStorage.getItem("tariff.trial") === "true") {
        this.items = data.results.filter(tariff => !tariff.is_trial);
      }

      if (this.$route.params.id) {
        const activeTariff = this.items.find(
          x => +x.id === +this.$route.params.id
        );
        if (activeTariff) {
          this.tariff = activeTariff;

          this.$nextTick(() => {
            document
              .querySelector(".tariffs-body__item.active")
              .scrollIntoView();
          });
        } else {
          this.tariff = this.items[0];
        }
      } else {
        this.tariff = this.items.find(el => el.days_count === 365);
      }
    });
  },

  filters: {
    plurals: (n, titles) =>
      n +
      " " +
      titles[
        n % 10 === 1 && n % 100 !== 11
          ? 0
          : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? 1
          : 2
      ]
  }
};
</script>
