<template>
  <div class="pay-modal">
    <div class="pay-modal__title">Выберите способ оплаты</div>
    <div class="pay-modal__desc">
      <slot name="description" />
    </div>

    <slot name="errors" />

    <div class="pay-variants">
      <div class="pay-variant">
        <div class="pay-variant__title">Картой</div>
        <VButton
          class="v-button--default v-button--active v-button--small"
          @click="onPayByCard"
        >
          Оплатить
        </VButton>
      </div>
      <div class="pay-variant">
        <div>
          <div class="pay-variant__title">C баланса</div>
          <div class="pay-variant__desc">
            Ваш баланс: {{ user.balance / 100 }}₽
          </div>
        </div>
        <VButton
          class="v-button--default v-button--active v-button--small"
          @click="onPayByBalance"
        >
          Оплатить
        </VButton>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/Form/VButton";
import { activateGoal } from "@/helpers/metric";
import { mapGetters } from "vuex";

export default {
  name: "pay-variants",

  components: { VButton },

  computed: {
    ...mapGetters(["user", "authorized"])
  },

  methods: {
    onPayByCard() {
      activateGoal("oplata");
      this.$emit("payByCard");
    },
    onPayByBalance() {
      activateGoal("oplata");
      this.$emit("payByBalance");
    }
  }
};
</script>

<style scoped lang="scss">
.pay-modal {
  &__title {
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__desc {
    color: white;
    margin-bottom: 30px;
    text-align: center;
  }
}

.pay-variants {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media all and (max-width: 479px) {
    grid-template-columns: 1fr;
  }
}

.pay-variant {
  display: grid;
  grid-template-rows: 80px auto;
  background-image: linear-gradient(to top, #06182e, #091d36);
  border: 1px solid transparent;
  padding: 24px;
  border-radius: 10px;

  &:hover {
    background-image: none;
    border-color: #1d7add;
  }

  &__title {
    color: white;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__desc {
    color: white;
    font-size: 14px;
  }
}
</style>
