export default {
    namespaced: true,
    state: {
        questions: []
    },
    mutations: {
        SET_QUESTIONS(state, payload) {
            state.questions = payload;
        }
    },
    actions: {
        toggleQuestion({ state }, { id, isOpen }) {
            const questionToChange = state.questions.find(el => el.id === id);
            questionToChange.active = isOpen;
        }
    }
};
