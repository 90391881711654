export default {
    namespaced: true,
    state: {
        film: null,
        special: false,
        showFilm: true,
        reviews: {
            items: [],
            offset: 5,
            total: 0
        },
        viewFilms: new Set(),
        currentSeason: null,
        currentEpisode: null
    },
    mutations: {
        set: (state, payload) => {
            state.film = payload;
            if (state.film.announcement) return;

            if (state.film.is_film) {
                state.film.seasonsMap = new Map();
                state.film.seasonsPosters = new Map();
            } else {
                state.film.seasonsMap = new Map(
                    state.film.list.map(season => [
                        season.season_number,
                        new Map(season.series.map(ep => [ep.series, ep]))
                    ])
                );
                state.film.seasonsPosters = new Map(
                    state.film.list.map(season => [
                        season.season_number,
                        season.season_poster
                    ])
                );

                state.film.seasonsInfo = new Map(
                    state.film.list.map(season => {
                        const { series, ...info } = season;
                        return [season.season_number, info];
                    })
                );
            }
        },
        preload: state => {
            state.film.name = "";
            state.film.img = "";
            state.film.attribs = [];
            state.film.comments = [];
            state.film.reviews = [];
            state.film.recommends = [];
            state.film.list = [];
        },
        setShow: (state, payload) => {
            state.showFilm = payload;
            state.film.showFilm = payload;
        },
        reviews: (state, payload) => {
            state.reviews = payload;
        },
        addViewFilm: (state, payload) => {
            state.viewFilms.add(payload);
        },
        setName: (state, payload) => {
            state.film.title = payload;
        },
        setCurrentEpisode: (state, payload) => (state.currentEpisode = payload),
        setCurrentSeason: (state, payload) => (state.currentSeason = payload),
        setSpecial: (state, payload) => (state.special = payload)
    },
    actions: {
        load: ({ commit }, payload) => {
            commit("preload");
            this.$axios.$post(`film/${payload.film}/`).then(film => {
                film.currentSeason = parseInt(payload.season);
                film.currentEpisode = parseInt(payload.series);
                commit("set", film);
            });
        },

        reviews({ commit }, payload) {
            this.$axios
                .$get(`feedbacks/?film=${payload.id}&page=${payload.page}&page_size=5`)
                .then(response => {
                    commit("reviews", response);
                });
        },

        increaseView({ commit, state, $cookies }, payload) {
            if (!state.viewFilms.has(payload)) {
                this.$axios.$put(`film/increase-view/${payload}/`);
                commit("addViewFilm", payload);
                this.$cookies.set("view-film", Array.from(state.viewFilms), {
                    path: "/",
                    maxAge: 60 * 60 * 24 * 7
                });
            }
        }
    }
};
