<template lang="pug">
p.mainbar__agreement-text
    = "Нажимая на кнопку «Вход или регистрация» вы принимаете условия "
    button(@click.prevent="$bus.$emit('change.layout', 'terms', 'api/v4/terms/user/', 'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ' )") Пользовательского соглашения
    = " и даёте согласие на "
    button(@click.prevent="$bus.$emit('change.layout', 'terms', 'api/v4/terms/private-policy/', 'Политика конфиденциальности'.toUpperCase())") обработку персональных данных.
</template>

<script>
export default {
  name: "agreement"
};
</script>
