const API_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API : "";

import axios from "axios";

function vtoken(today) {
    const year = today.getUTCFullYear() + "";
    const month = today.getUTCMonth() + 1;
    const day = today.getUTCDate();
    const hour = today.getUTCHours();
    let word = year + month + day + hour;
    word += "KEXITYTYFLJ";
    return Buffer.from(word)
        .toString("base64")
        .replace(/=/g, "")
        .replace(/b/g, "c");
}

if (
    sessionStorage.hasOwnProperty("user.authorized") &&
  localStorage.hasOwnProperty("token")
) {
    axios.defaults.headers.common["Authorization"] =
    "JWT " + localStorage.getItem("token");
}

axios.defaults.headers.post["Content-Type"] =
  "multipart/form-data; boundary=" +
  Math.random()
      .toString()
      .substr(2);

const http = axios.create({
    baseURL: API_URL,
    headers: {
        "v-token": vtoken(new Date())
    }
});

export default http;
