<template lang="pug">
.modal
    .modal__overlay
    .modal__body(:class="bodyClass")
        .modal__container.uk-position-relative
            svg.modal__close(
                v-if="!noClose"
                @click="close"
            )
                use(xlink:href="#close")
            .modal__content(:class="type")
                slot
</template>

<script>
export default {
  name: "modal",

  props: {
    type: {
      type: String,
      default: ""
    },
    bodyClass: {
      type: String,
      default: ""
    },
    noClose: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close() {
      if (this.$route.name === "terms" || this.$route.name === "cookie") {
        this.$router.push({ name: "home" });
      } else {
        this.$bus.$emit(
          this.type === "modal__content--terms"
            ? "close.terms"
            : "change.layout",
          ""
        );
      }
    }
  },

  mounted() {
    document.getElementsByTagName("body")[0].classList.add("fix-modal");
  },

  destroyed() {
    document.getElementsByTagName("body")[0].classList.remove("fix-modal");
  }
};
</script>
