export const FOOTER_MENU_CENTER = [
    {
        name: "Политика обработки персональных данных",
        to: "/private-policy"
    },
    {
        name: "Генеральное пользовательское соглашение",
        to: "/user"
    },
    {
        name: "Соглашение правообладателей",
        to: "/holder-policy"
    }
];

export const DOCUMENT_COPYRIGHT =  [
    {
        name: 'Прокатное удостоверение Другая Реальность.pdf',
        to: '/media/Agreement-Filmbay-Karbush.pdf',
    },
    {
        name: 'Agreement Filmbay Karbush.pdf',
        to: '/media/Agreement-Karbush-Kinoflex.pdf',
    },
    {
        name: 'Agreement Karbush Kinoflex.pdf',
        to: '/media/rental-certificate-Drugaya-Realnost.pdf',
    },
];
