<template lang="pug">
Modal
    template
        .mainbar__title Авторизация
        form.auth(@submit.prevent="submitForm")
            InputField.auth__field(
                type="tel"
                icon="user"
                placeholder="Email, телефон или никнейм"
                v-model="login"
                autocomplete="tel"
            )
            InputField.auth__field(
                type="password"
                icon="lock"
                placeholder="Пароль"
                v-model="password"
                autocomplete="current-password"
            )

            Errors(
                :errors="errors"
                v-if="Object.keys(errors).length"
            )

            button.auth__btn.auth__btn--login(:disabled="buttonDisabled") Войти
            button.auth__btn.auth__btn--registration(
                :disabled="buttonDisabled"
                type="button"
                @click="$emit('change', 'register')"
            ) Регистрация
            router-link.auth__btn.auth__btn--trailers.uk-flex.uk-flex-middle.uk-flex-center(:to="{name: 'serials'}" @click.native="$emit('change', '')")
                .icon-play
                span Сериалы

    Agreement
    button.mainbar__reset-btn(@click="$bus.$emit('change.layout', 'reset')") Восстановление пароля
    br
    ContactUs
</template>

<script>
import InputField from "@/components/InputField";
import Errors from "@/components/Errors";
import authMixins from "@/mixins/auth";
import Modal from "@/components/Modal";
import Agreement from "@/components/Agreement";
import ContactUs from "@/components/ContactUs";

export default {
  name: "auth-form",

  mixins: [authMixins],

  components: {
    ContactUs,
    Agreement,
    Modal,
    InputField,
    Errors
  }
};
</script>
