<template>
  <ul class="pathway">
    <template v-for="(item, index) in list">
      <li class="pathway__item" :key="item.name">
        <router-link
          :to="item.link"
          class="pathway__link"
          v-if="index < list.length - 1"
          >{{ item.name }}</router-link
        >
        <template v-else>{{ item.name }}</template>
      </li>
      <li
        v-if="index < list.length - 1"
        class="pathway__separator"
        :key="`separator_${item.name}`"
      >
        /
      </li>
    </template>
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "pathway",

  computed: {
    ...mapState("pathway", ["list"])
  }
};
</script>

<style lang="scss">
.pathway {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0;

  &__item {
  }

  &__separator {
    margin: 0 15px;
    color: #ffffff;
  }

  &__link {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__item {
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>
