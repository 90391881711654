<template lang="pug">
    div
        template(v-if="layout === 'start'")
            .mainbar__title Восстановление пароля
            form.register.uk-flex.uk-flex-column(@submit.prevent="getCaptchaToken")
                .reset-field-container
                    InputField.auth__field--reset.field--reset(
                        :type="inputType"
                        icon="user"
                        :placeholder="placeholder"
                        v-model="login"
                        autocomplete="on"
                    )

                    InputToggle(@toggle="changeInputType($event)" v-if="!isDomainKg")
                vue-recaptcha(ref="recaptcha" v-bind="recaptchaSettings" @verify="(token) => startMethod(token, 'email')")
                Errors(
                    :errors="errors"
                    v-if="showErrors"
                )

                button.register__btn(
                    type="submit"
                    :disabled="buttonDisabled"
                ) Восстановить

                button.register__account-btn(@click="$router.push({name: 'Login'})") Есть аккаунт? Войти

        template(v-if="layout !== 'start'")
            .mainbar__title Восстановление пароля
            .confirmation.uk-flex.uk-flex-column
                template(v-if="layout === 'call'")
                    p.confirmation__text На указанный номер придет смс с кодом для продолжения восстановления пароля, для этого нажмите на кнопку - "Получить смс" и введите полученный код в открывшемся поле "Код подтверждения"
                    Errors(
                        :errors="errors"
                        v-if="showErrors"
                    )
                    vue-recaptcha(ref="recaptcha" v-bind="recaptchaSettings" @verify="(token) => startMethod(token, 'phone')")
                    button.confirmation__button(@click.prevent="getCaptchaToken") Получить смс

                template(v-if="layout === 'confirm-code'")
                    form.register.uk-flex.uk-flex-column(@submit.prevent="confirmCode")
                        .confirmation__user.uk-margin-bottom
                            img(
                                alt=""
                                :src="resetData.avatar_url"
                                v-if="resetData.avatar_url"
                            )
                            img(
                                v-else
                                src="@/assets/images/auth/profile_pic.png"
                                alt="")
                            span {{ resetData.username }}
                        p.confirmation__text {{ confirmCodeText }}
                        InputField.confirmation__field(
                            type="text"
                            placeholder="Код подтверждения"
                            icon="mail-modal"
                            v-model="code"
                            mod="modal"
                            max-length="4"
                        )

                        Errors.uk-margin-bottom(
                            :errors="errors"
                            v-if="showErrors"
                        )

                        button.confirmation__button(
                            type='submit'
                            :disabled="buttonDisabled"
                        ) Подтвердить

                        button.confirmation__button.confirmation__button--resend(
                            @click="getCaptchaToken"
                            type='button'
                            :disabled = "repeatTime > 0"
                        ) Выслать код повторно
                        vue-recaptcha(ref="recaptcha" v-bind="recaptchaSettings" @verify="(token) => startMethod(token, 'repeat')")
                        p.confirmation__repeat-text(v-if="repeatTime > 0") Повторная отправка возможна через {{ repeatTime | plurals(['секунду', 'секунды', 'секунд']) }}

                template(v-if="layout === 'change-password'")
                    form.register.uk-flex.uk-flex-column(@submit.prevent="doReset")
                        p.confirmation__text Придумайте новый пароль.
                        InputField.register__field(
                            type="password"
                            icon="lock"
                            placeholder="Пароль"
                            v-model="form.password1"
                            mod="modal"
                            autocomplete="new-password"
                        )
                        InputField.register__field(
                            type="password"
                            icon="lock"
                            placeholder="Повторите пароль"
                            v-model="form.password2"
                            mod="modal"
                            autocomplete="new-password"
                        )
                        Errors(
                            :errors="errors"
                            v-if="showErrors"
                        )
                        button.confirmation__button.confirmation__button--reset(
                            type='submit'
                            :disabled="buttonDisabled"
                        ) Изменить пароль

                template(v-if="layout === 'complete'")
                    svg.confirmation__icon
                        use(xlink:href='#done')
                    p.confirmation__title Пароль изменен
                    p.confirmation__text.confirmation__text--done Вы успешно изменили пароль от своего личного кабинета
                    button.confirmation__button(@click="doLogin") Войти
</template>

<script>
import Modal from "@/components/Modal";
import InputField from "@/components/InputField";
import InputToggle from "@/components/InputToggle";
import Errors from "@/components/Errors";
import validatePassword from "@/mixins/validatePassword";
import ResetMixins from "@/mixins/reset";
import utils from "@/mixins/utils";
import {VueRecaptcha} from 'vue-recaptcha';

export default {
  name: "reset-form",

  data: () => ({
    togglePresetValue: null,
    login: "",
  }),

  components: {
    Modal,
    InputField,
    InputToggle,
    Errors, VueRecaptcha
  },

  mixins: [validatePassword, ResetMixins, utils]
};
</script>
