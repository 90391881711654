<template lang="pug">
    Modal(no-close v-if="success" )
        template(v-if="loading")
            .uk-text-center
                img(src="~@/assets/images/loader.svg" alt)
        template(v-else)
            .confirmation.confirmation--done.uk-flex.uk-flex-column
                svg.confirmation__icon
                    use(xlink:href='#done')
                p.confirmation__title(style="color: white;") Поздравляем! Вы успешно активировали тариф
                p.confirmation__text Теперь вам доступны сотни сериалов в профессиональной озвучке TVShows.
                p.confirmation__text Приятного просмотра!
                button.confirmation__button(@click="$bus.$emit('user.auth')") Смотреть
    Modal(no-close v-else)
        template
            .confirmation.confirmation--done.uk-flex.uk-flex-column
                p.confirmation__title(style="color: white;") Упс... Во время покупки произошла ошибка
                p.confirmation__text Попробуйте ещё раз или напишите в нашу техподдержку на support@kinoflex.tv, и мы обязательно со всем разберёмся
                button.confirmation__button(@click="tryAgain") повторить
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "PayFail",

  components: {
    Modal
  },

  data: () => ({
    success: false
  }),

  methods: {
    tryAgain() {
      this.$bus.$emit("user.auth");
      this.$nextTick(() => {
        this.$router.push({ name: "home" });
      });
    }
  },

  created() {
    const success = this.$route.query.success;
    if (success === "true") {
      try {
        VK.Goal("purchase");
      } catch (_) {}
      this.success = true;
    } else {
      this.success = false;
    }
  }
};
</script>
