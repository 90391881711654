<template lang="pug">
.uk-container
    .advertising
        .advertising__info
            .title.title--adv.title--serials Смотрите любимые сериалы без рекламы
            .desc На нашем сервисе вы не увидите назойливую рекламу казино
        .advertising__image

            img(src="@/assets/images/scruffy.png" alt="")
        .advertising__button
            button.button.uk-flex.uk-flex-center.uk-flex-middle.uk-margin-bottom(type="button" @click.prevent="$bus.$emit('change.layout', 'register')") Оформите бесплатную подписку *
            .uk-text-center.uk-text-small(style="margin-bottom: 130px;") * Бесплатная подписка на 1 год, не требуются платежные данные, продление также бесплатное.
</template>

<script>
export default {
  name: "advertising"
};
</script>
