<template lang="pug">
    .auth-page
        header.serials-page__header
            HeaderBase
            HeaderMenu
        main.auth-page__container
            AuthWrapper(:hide-description="true" :hide-custom="layoutConfirm")
                template(#custom-subtitle) Осталось немного
                template(#custom-label v-if="isPhoneRegister" ) Введите проверочный код, который мы отправили вам по смс на номер, указанный при регистрации.
                template(#custom-label v-else) Введите проверочный код, который мы отправили вам на почту, указанную при регистрации.
                template(v-if="!layoutConfirm")
                    VCode(
                        :length="4"
                        :fontSize="42"
                        inputColor="#ffffff"
                        fontColor="#FFFFFF"
                        :allowPaste="true"
                        v-model="code"
                    )
                    vue-recaptcha(ref="recaptcha" v-bind="recaptchaSettings" @verify="sendCode")
                    Errors.uk-margin-bottom(
                        :errors="errors"
                        v-if="hasErrors"
                    )
                    .register__button-container.register__button-container--back
                        button.register__btn-back(
                            @click="$router.push({name: 'RegisterPassword'})"
                        )
                        button.register__btn(
                            @click="checkCode"
                            :disabled="buttonDisabled"
                        ) Подтвердить
                    button.register__btn.register__btn--confirm(
                        @click="repeatCode"
                        :disabled="repeatTime > 0"
                    ) Выслать код повторно
                    p.confirmation__repeat-text(v-if="repeatTime > 0") Повторная отправка возможна через {{ repeatTime | plurals(['секунду', 'секунды', 'секунд']) }}
                template(#footer)
                    div(:style="{marginTop: '141px'}")
                template(v-if="layoutConfirm")
                    .confirmation.confirmation--done.uk-flex.uk-flex-column
                        svg.confirmation__icon
                            use(xlink:href='#done')
                        p.confirmation__title Спасибо за регистрацию!
                        p.confirmation__text.confirmation__text--done Вы успешно зарегистрировались!
                        button.register__btn.register__btn--confirm(@click="chooseTariff") ВОЙТИ В FLEX


</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import AuthWrapper from "@/views/auth/components/AuthWrapper";
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";
import VCode from "@/components/Form/VCode";
import { activateGoal } from "@/helpers/metric";
import {VueRecaptcha} from 'vue-recaptcha';

export default {
  name: "RegisterPassword",

  components: {
    AuthWrapper,
    HeaderBase,
    HeaderMenu,
    InputField,
    Errors,
    VCode, VueRecaptcha
  },
  mixins: [validatePassword, RegisterMixins, utils],
  data: () => ({
    lockEmailRegister: false
  }),
    created() {
    if (!this.form.email && !this.form.phone) {
        this.$router.push({ name: "home" });
    }
  },
    mounted() {
        this.$nextTick(async () => {
            if(!this.$refs?.recaptcha) return;
            try {
                await this.$refs?.recaptcha?.execute();
            } catch (e) {
                console.log(e);
            }
            this.runRepeat();
        });
    },
    methods: {
    chooseTariff() {
      activateGoal("confirmtarif");
      this.login();
    }
  }
};
</script>
