<template lang="pug">
.voice-acting
    .title.title--serials Смотрите в качественной озвучке
    .logo__voice.uk-text-center
        img(src="@/assets/images/logo-tvshows.png" alt="")
    .uk-container
        button.button.button--voice.uk-flex.uk-flex-center.uk-flex-middle(type="button" @click="redirectRegister") ЗАРЕГИСТРИРОВАТЬСЯ

</template>

<script>
import { activateGoal } from "@/helpers/metric";

export default {
  name: "voice-acting",
  methods: {
    redirectRegister() {
      activateGoal("butregistr");
      this.$router.push({ name: "Register" });
    }
  }
};
</script>
