<template lang="pug">
    Modal(
        type="modal__content--terms"
    )
        .terms__title
            slot(name="title")
        slot
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "terms",

  components: {
    Modal
  }
};
</script>
