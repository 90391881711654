<template lang="pug">
Modal(no-close)
    .confirmation.confirmation--done.uk-flex.uk-flex-column
        svg.confirmation__icon
            use(xlink:href='#done')
        p.confirmation__title(style="color: white;") Поздравляем! Вы успешно активировали тариф
        p.confirmation__text Теперь вам доступны сотни сериалов в профессиональной озвучке TVShows.
        p.confirmation__text Приятного просмотра!
        button.confirmation__button(@click="$bus.$emit('user.auth')") Смотреть

</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "Complete",

  components: {
    Modal
  }
};
</script>
