const YMetricId = process.env.VUE_APP_YA_METRIC;

export const addCart = ({ tariff }) => {
    dataLayer.push({
        ecommerce: {
            currencyCode: "RUB",
            add: {
                products: [
                    {
                        name: tariff.days_count + " дней",
                        price: tariff.discounted_price / 100,
                        category: "Покупка тарифа",
                        quantity: 1
                    }
                ]
            }
        }
    });
};

export const addBuying = ({ tariff, id, coupon }) => {
    dataLayer.push({
        ecommerce: {
            currencyCode: "RUB",
            purchase: {
                actionField: {
                    id: id || `PR_${Date.now()}`,
                    ...(!!coupon && { coupon })
                },
                products: [
                    {
                        name: tariff.days_count + " дней",
                        price: tariff.discounted_price / 100,
                        category: "Покупка тарифа",
                        quantity: 1
                    }
                ]
            }
        }
    });
};

export const activateGoal = symbol => {
    ym(YMetricId, "reachGoal", symbol);
};

export const saveTariffPayload = ({ tariff, id, coupon }) => {
    sessionStorage.setItem("cart.tariff", JSON.stringify(tariff));
    sessionStorage.setItem("cart.coupon", JSON.stringify(coupon));
    sessionStorage.setItem("cart.buying", id);
};

export const removeTariffPayload = () => {
    sessionStorage.removeItem("cart.buying");
    sessionStorage.removeItem("cart.tariff");
    sessionStorage.removeItem("cart.coupon");
};
