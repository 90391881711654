<template lang="pug">
    .auth-wrapper(:class="{'auth-wrapper--login': login}")
        .auth-wrapper__left(v-if="login")
        .auth-wrapper__right
            .auth-wrapper__head
                .auth-wrapper__logo
                    //span.logo-text онлайн-кинотеатр
                    img.logo(src="@/assets/scss/images/logo.png" alt="")
                //router-link.auth-wrapper__close(v-if="!hideClose" :to="{name: 'home'}")
            .auth-wrapper__custom-subtitle(v-if="$slots['custom-subtitle'] && !hideCustom")
                slot(name="custom-subtitle")
            .auth-wrapper__custom-label(v-if="$slots['custom-label'] && !hideCustom")
                slot(name="custom-label")
            .auth-wrapper__description(v-if="!hideDescription")
                //.auth-wrapper__title 14 дней <strong>бесплатно</strong>, <br/> далее от 150₽ в месяц
                .auth-wrapper__features(v-for="item in $options.FEATURES_LIST")
                    img(src="@/assets/images/svg/star.svg")
                    .auth-wrapper__features-title {{ item.title }}
                    .auth-wrapper__features-describe {{ item.describe }}

            .auth-wrapper__body
                slot
            .auth-wrapper__foot
                .auth-wrapper__foot-description
                    slot(name="footer")
                //.auth-wrapper__foot-socials(v-if="!isDomainKg")
                //    contact-us(:describe="false" :policyVisible="false" docs)

</template>
<script>
import ContactUs from "@/components/ContactUs";
import validatePassword from '@/mixins/validatePassword';
import RegisterMixins from '@/mixins/register';
import utils from '@/mixins/utils';

const currentYear = new Date().getFullYear();

const FEATURES_LIST = [
  {
    title: "Тысячи фильмов и сериалов",
    describe: `От ведущих мировых киностудий, включая новинки ${currentYear}`,
  },
  {
    title: "Просмотр без рекламы",
    describe: "Ничего не помешает наслаждаться просмотром"
  },
  {
    title: "Озвучено TVShows",
    describe: "Профессиональная многоголосая озвучка всего контента"
  },
  {
    title: "Качество фильмов и сериалов",
    describe: "Разрешение Full HD 1080 и объемный звук 5.1"
  },
  {
    title: "На любом устройстве",
    describe: "На сайте, в мобильных приложениях для iOS и Android и в ТВ-приложениях для Apple TV, Android TV, Samsung (TizenOS), LG (WebOS) и Яндекс.ТВ"
  }
];
export default {
  name: "auth-wrapper",
  components: { ContactUs }, mixins: [utils],
  FEATURES_LIST,
  props: {
    login: {
      type: Boolean,
      default: false
    },
    hideDescription: {
      type: Boolean,
      default: false
    },
    hideCustom: {
      type: Boolean,
      default: false
    },
      hideClose: {
        type: Boolean,
          default: false
      }
  }
};
</script>
<style lang="scss">
.auth-wrapper {
  //margin: auto;
  //background: #060e20;
  border-radius: 14px;
  width: 524px;
  display: grid;
  overflow: hidden;

  @media (max-width: 690px) {
    width: 100%;
  }
  &--login {
    width: 1066px;
    grid-template-columns: 1fr 1fr;
    .auth-wrapper__logo {
      margin-bottom: 32px;
    }
    .auth-wrapper__custom-label {
      margin: 8px 0 32px;
    }
    @media (max-width: 1100px) {
      width: 670px;
      grid-template-columns: 1fr;
      .auth-wrapper__left {
        display: none;
      }
    }
    @media (max-width: 690px) {
      width: 100%;
    }
  }
  &__left {
    background: url("~@/assets/images/bg-login.webp") no-repeat center center;
    background-size: cover;
  }
  &__right {
    display: grid;
      gap: 32px;
    padding: 0 64px;
    //@media (max-width: 1440px) {
    //  padding: 24px 23px 16px;
    //}
    @media (max-width: 450px) {
      padding: 16px;
    }
    @media (max-width: 370px) {
      padding: 6px;
    }
  }
  &__head {
    position: relative;
  }
  &__logo {
    display: grid;
    .logo-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 8px;
    }
    .logo {
      width: 141px;
      justify-self: center;
    }
    @media (max-width: 450px) {
      margin-top: 16px;
    }
  }
  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: #2a384a;
      transform: rotate(45deg);
      border-radius: 4px;
      transition: 0.4s;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: #2a384a;
      transform: rotate(-45deg);
      border-radius: 4px;
      transition: 0.4s;
    }
    &:hover {
      &:before,
      &:after {
        background-color: #656e7a;
      }
    }
  }
  &__custom-subtitle {
    max-width: 400px;
    justify-self: center;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #b4b9c0;
    margin: 40px 0 -16px;
  }
  &__custom-label {
    display: grid;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #838ca4;
    margin: 40px 0 32px;
    max-width: 400px;
    justify-self: center;
  }
  &__description {
    max-width: 400px;
    justify-self: center;
    margin: 40px 0;
    @media (max-width: 1920px) {
      margin: 24px 0;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #b4b9c0;
    margin-bottom: 40px;
    strong {
      color: #ffffff;
    }
    @media (max-width: 450px) {
      margin-bottom: 0;
      font-size: 20px;
    }
    @media (max-width: 1920px) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
  &__features {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: auto 1fr;
    margin-bottom: 10px;
    img {
      grid-row-start: 1;
      grid-row-end: 3;
      align-self: center;
    }
    @media (max-width: 1920px) {
      margin-bottom: 4px;
    }
    @media (max-width: 450px) {
      display: none;
    }
  }
  &__features-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #ffffff;
    grid-column: 2;
    @media (max-width: 1920px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__features-describe {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #60667c;
    grid-column: 2;
    @media (max-width: 1920px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  &__body {
    max-width: 400px;
    justify-self: center;
    width: 100%;
    display: grid;
  }
  &__foot {
    max-width: 400px;
    justify-self: center;
  }
  &__foot-description {
      color: rgba(255, 255, 255, 0.60);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      span {
      color: #DDFF7C;
      cursor: pointer;
    }
    @media (max-width: 450px) {
      font-size: 12px;
    }
  }
  &__foot-socials {
    .contact-us {
      margin-bottom: 0;
    }
  }
}
</style>
