import { mapState } from "vuex";
import {
    emailRegex,
    numbersRegex,
    lettersRegex,
    whitespaceRegex,
    unicodeRegex,
    phoneRegex
} from "@/helpers/regex";
import { getCleanPhoneNumber } from "@/helpers/common";

const form = {
    password1: "",
    password2: ""
};

export default {
    data: () => ({
        errors: {},
        buttonDisabled: false,
        inputType: "text",
        layout: "start",
        repeatTime: 0,
        repeatInterval: null,
        savedLogin: {},
        code: "",
        regType: "phone",
        showPassword1: false,
        showPassword2: false,
        form: {
            ...form
        },
        resetData: {
            avatar_url: null,
            username: null
        }
    }),

    computed: {
        ...mapState(["isLocalHost"]),
        codeIsNotCorrect: vm => !(vm.code && vm.code.length === 4),
        codeDisabled: vm => vm.code.split(" ").join("").length < 4,
        confirmCodeText: vm =>
            vm.regType === "phone"
                ? "Введите в поле ниже 4-х значный код из смс"
                : "Введите проверочный код, который мы вам выслали на почту указанную вами при регистрации.",
        placeholder() {
            const contact =
        ((this.inputType === "email" || this.inputType === "text") &&
          "E-mail") ||
        (this.inputType === "tel" && "номер телефона");
            return `Ваш ${contact}`;
        },
        showErrors: vm => vm.errors && Object.keys(vm.errors).length
    },

    methods: {
        async getCaptchaToken(token) {
            try {
                await this.$refs.recaptcha.execute();
            } catch (e) {
                console.log(e);
            }
        },
        async startMethod(token, type) {
            if (type === "email") await this.startReset(token);
            if (type === "phone") await this.callMe(token);
            if (type === "repeat") await this.repeatCode(token);
        },

        async startReset(token) {
            this.buttonDisabled = true;
            this.errors = {};

            this.login = this.login.trim();

            if (!this.login.length) {
                this.errors.non_field_errors = ["Это поле не может быть пустым"];
            } else if (this.inputType === "text" && !emailRegex.test(this.login)) {
                this.errors.login = ["Введите корректный адрес электронной почты"];
            } else if (this.inputType === "tel" && !phoneRegex.test(this.login)) {
                this.errors.login = ["Введите корректный номер телефона"];
            }

            if (this.errors && Object.keys(this.errors).length > 0) {
                this.buttonDisabled = false;
                return;
            }

            const form = {
                reset: true
            };

            if (emailRegex.test(this.login)) {
                form.email = this.login;
                this.regType = "email";
            } else if (phoneRegex.test(this.login)) {
                this.regType = "phone";

                form.phone = getCleanPhoneNumber(this.login);

                try {
                    await this.$axios.post(`api/v4/auth/validate-userdata/`, form);
                    this.layout = "call";
                } catch ({ response }) {
                    if (typeof response.data === "string") {
                        this.errors = {
                            non_field_errors: "Возникла непредвиденная ошибка"
                        };
                    } else {
                        this.errors = response.data;
                    }
                } finally {
                    this.buttonDisabled = false;
                }
                return;
            }

            try {
                form.recaptcha = token;
                const { data } = await this.$axios.post(`api/v4/auth/send-code/`, form);
                this.layout = "confirm-code";
                this.resetData = { ...data };
                this.runRepeat();
            } catch (err) {
                const { response } = err;
                if (typeof response.data === "string") {
                    this.errors = {
                        non_field_errors: "Возникла непредвиденная ошибка"
                    };
                } else {
                    this.errors = response.data;
                }
            } finally {
                this.buttonDisabled = false;
            }
        },

        callMe(token) {
            this.errors = null;
            this.buttonDisabled = true;
            this.$axios
                .post(`api/v4/auth/send-code/`, {
                    phone: getCleanPhoneNumber(this.login),
                    reset: true,
                    recaptcha: token,
                })
                .then(data => {
                    this.resetData = {
                        ...data.data
                    };
                    this.layout = "confirm-code";
                    this.runRepeat();
                })
                .catch(({ response }) => {
                    if (typeof response.data === "string") {
                        this.errors = {
                            non_field_errors: "Возникла непредвиденная ошибка"
                        };
                    } else {
                        this.savedLogin.type = "tel";
                        this.savedLogin.value = this.login;
                        this.layout = "start";
                        this.inputType = "tel";
                        this.$store.commit("SET_TOGGLE", true);
                        this.errors = response.data;
                    }
                })
                .finally(() => (this.buttonDisabled = false));
        },

        runRepeat() {
            this.repeatTime = 180;
            this.repeatInterval = setInterval(() => {
                this.repeatTime -= 1;
                if (this.repeatTime <= 0) {
                    clearInterval(this.repeatInterval);
                }
            }, 1000);
        },

        repeatCode(token) {
            this.errors = null;
            const form = {
                reset: true,
                recaptcha: token,
            };
            if (this.regType === "email") {
                form.email = this.login;
            } else {
                form.phone = getCleanPhoneNumber(this.login);
            }

            this.$axios
                .post(`api/v4/auth/send-code/`, form)
                .then(() => {
                    this.runRepeat();
                })
                .catch(({ response }) => {
                    if (typeof response.data === "string") {
                        this.errors = {
                            non_field_errors: "Возникла непредвиденная ошибка"
                        };
                    } else {
                        this.errors = response.data;
                    }
                });
        },

        confirmCode() {
            this.errors = null;
            this.buttonDisabled = true;
            const form = {
                code: this.code
                    .trim()
                    .split(" ")
                    .join("")
            };

            if (this.regType === "email") {
                form.email = this.login.trim();
            } else {
                form.phone = getCleanPhoneNumber(this.login);
            }

            this.$axios
                .post(`api/v4/auth/check-code/`, form)
                .then(() => {
                    this.layout = "change-password";
                })
                .catch(({ response }) => {
                    if (typeof response.data === "string") {
                        this.errors = {
                            non_field_errors: "Возникла непредвиденная ошибка"
                        };
                    } else {
                        this.errors = response.data;
                    }
                })
                .finally(() => (this.buttonDisabled = false));
        },

        doReset() {
            this.errors = null;
            this.buttonDisabled = true;

            this.form.code = this.code
                .trim()
                .split(" ")
                .join("");

            if (this.regType === "email") {
                this.form.email = this.login;
            } else {
                this.form.phone = getCleanPhoneNumber(this.login);
            }

            // Client validation
            if (!this.validate_fields()) {
                this.buttonDisabled = false;
                return;
            }

            this.$axios
                .post(`api/v4/auth/reset-password/`, this.form)
                .then(() => {
                    this.layout = "complete";
                })
                .catch(({ response }) => {
                    if (typeof response.data === "string") {
                        this.errors = {
                            non_field_errors: "Возникла непредвиденная ошибка"
                        };
                    } else {
                        this.errors = response.data;
                    }
                })
                .finally(() => (this.buttonDisabled = false));
        },

        validate_fields() {
            const password = this.form.password1;
            const passwordErrors = [];
            if (password.length < 8) {
                passwordErrors.push("Минимальная длина пароля должна быть 8 знаков.");
            }
            if (!numbersRegex.test(password)) {
                passwordErrors.push("Пароль должен содержать цифры.");
            }
            lettersRegex.lastIndex = unicodeRegex.lastIndex = 0;
            if (!lettersRegex.test(password) || unicodeRegex.test(password)) {
                passwordErrors.push(
                    "Пароль должен содержать латинские буквы, без кириллицы. Можно использовать символы."
                );
            }
            if (whitespaceRegex.test(password)) {
                passwordErrors.push("Пароль должен быть без пробелов.");
            }
            if (password !== this.form.password2) {
                passwordErrors.push("Пароли не совпадают.");
            }
            if (passwordErrors.length > 0) {
                this.errors = {
                    password: passwordErrors
                };
            }

            if (!this.errors) {
                return true;
            }

            return !(Object.keys(this.errors).length > 0);
        },

        codeOnInput(e) {
            const charCode = e.which ? e.which : e.keyCode;
            if (
                !(charCode === 86 && (e.ctrlKey || e.metaKey)) &&
        (e.ctrlKey ||
          e.shiftKey ||
          e.altKey ||
          e.metaKey ||
          ((charCode < 48 || charCode > 57) &&
            (charCode < 96 || charCode > 105) &&
            charCode !== 8 &&
            charCode !== 37 &&
            charCode !== 39 &&
            charCode !== 46))
            ) {
                e.preventDefault();
                return false;
            }
        },

        doLogin() {
            const form = {};
            form.password = this.form.password1;
            if (this.regType === "email") {
                form.email = this.login;
            } else {
                form.phone = getCleanPhoneNumber(this.login);
            }

            this.$axios.post("/api/v4/login/", form).then(({ data }) => {
                localStorage.setItem("token", data.token);
                localStorage.setItem("refresh_token", data.refresh_token);
                this.$axios.defaults.headers.common[
                    "Authorization"
                ] = `JWT ${data.token}`;
                this.$bus.$emit("user.auth");
                this.$router.push({ name: "home" }).catch(() => {});
            });
        },

        changeInputType(type) {
            if (this.savedLogin && this.savedLogin.type === type) {
                const savedLogin = this.savedLogin.value;
                if (this.login) {
                    this.savedLogin = {
                        type: this.inputType,
                        value: this.login
                    };
                }
                this.login = savedLogin;
            } else {
                if (this.savedLogin && this.savedLogin.value !== this.login) {
                    this.savedLogin = {
                        type: this.inputType,
                        value: this.login
                    };
                }
                this.login = "";
            }
            this.inputType = type;
            this.errors = {};
        }
    },

    filters: {
        phoneNumber(phone) {
            return phone
                .trim()
                .split(" ")
                .join("")
                .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4");
        }
    }
};
