<template>
  <button :type="type" class="v-button" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "v-button",

  props: {
    type: {
      type: String,
      default: "button"
    }
  }
};
</script>

<style lang="scss">
.v-button {
  background: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  border-radius: 8px;

  &--default {
    height: 55px;
    padding: 0 30px;
    backdrop-filter: blur(10px);
    background-color: #ed321a;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: 1.92px;
  }

  &--reset {
    background-color: #122337;
  }

  &--filter {
    height: 48px;
    padding: 0 16px;
    backdrop-filter: blur(10px);
    border: solid 1px #3369ff;
    background-color: #122337;
    font-weight: 500;

    @media all and (max-width: 479px) {
      height: 38px;
      padding: 0 10px;
    }
  }

  &--no-border {
    border: none;
  }

  &--active {
    background: #3369ff;
  }

  &--resp {
    font-size: 12px;
    letter-spacing: normal;
    height: 48px;
    @media all and (max-width: 479px) {
      height: 30px;
      max-width: 130px;
    }
  }

  &--small {
    font-size: 12px;
    letter-spacing: normal;
    height: 48px;
  }

  &--xs {
    font-size: 12px;
    letter-spacing: normal;
    height: 34px;
  }

  &--max-width {
    width: 100%;

    @media all and (max-width: 479px) {
      padding: 0 15px;
    }
  }

  &--subscription {
    height: 25px;
    padding: 0 12px;
    border-radius: 4px;
    border: solid 2px #f2f2f2;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: #f2f2f2;
    background: none;
    letter-spacing: normal;
  }

  &--delete {
    background-color: #122337;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--search {
    width: 32px;
    height: 32px;

    svg {
      width: 27px;
      height: 27px;
      fill: #b4b9c0;
      margin-top: 2px;
    }
  }

  &--menu {
    width: 32px;
    height: 32px;
    display: none;
    margin-left: 16px;
    position: relative;

    svg {
      width: 28px;
      height: 28px;
      fill: white;
    }

    @media all and (max-width: 1280px) {
      display: flex;
    }
  }

  &--tariff {
    width: 58px;
    height: 58px;
    border-radius: 8px;
    border: solid 2px #122337;
    background: none;
    box-shadow: none;
    margin: 0;
  }

  &--play {
    cursor: pointer;
    max-width: 236px;
    width: 100%;
    height: 64px;
    border-radius: 8px;
    background-color: #3369ff;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    white-space: nowrap;

    span {
      margin-right: 8px;
    }

    svg {
      width: 15px;
      height: 13px;
      fill: white;
      margin-right: 6px;
    }

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }

    @media all and (max-width: 680px) {
      line-height: 1.25;
      letter-spacing: 1.44px;
      margin-right: 0;
      height: 48px;
      max-width: none;
      font-size: 12px;
    }

    @media all and (max-width: 479px) {
      font-size: 10px;
      letter-spacing: 1.1px;
      padding: 0 15px;
    }
  }

  &--actor {
    white-space: nowrap;
    &.v-button--active {
      background-color: #3369ff;
      box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.5);
      @media (max-width: 680px) {
        box-shadow: none;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}
</style>
