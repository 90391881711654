<template>
  <div class="complete-pay">
    <div class="complete-pay__title">
      <slot name="title">Поздравляем! Вы успешно активировали тариф</slot>
    </div>
    <div class="complete-pay__text">
      <slot name="description"
        >Теперь вам доступны сотни сериалов в профессиональной озвучке
        TVShows.</slot
      >
    </div>
    <div class="complete-pay__text">Приятного просмотра!</div>
    <VButton
      class="v-button--default v-button--active complete-pay__button"
      @click="$emit('complete')"
    >
      Смотреть
    </VButton>
  </div>
</template>

<script>
import VButton from "@/components/Form/VButton";

export default {
  name: "tariff-pay-complete",

  components: { VButton }
};
</script>

<style lang="scss">
.complete-pay {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 50px 0;

  &__title {
    margin: 0 auto 25px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: white;
  }

  &__text {
    width: 100%;
    margin: 0 auto 15px;
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  &__button {
    margin-top: 30px;
    width: 100%;
  }
}
</style>
