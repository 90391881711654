<template lang="pug">
Modal(body-class="uk-flex")
    template
        .uk-margin-bottom
            button.register__btn.register__btn--blue(
                type="button"
                @click.prevent="$router.push({name:'Login'})"
            ) Авторизация
        div
            button.register__btn.register__btn--blue(
                type="button"
                @click.prevent="$router.push({name:'Register'})"
            ) Регистрация
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "mobile-select",

  components: {
    Modal
  }
};
</script>
