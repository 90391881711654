<template lang="pug">
    .header__top(data-uk-sticky="animation: uk-animation-fade;")
        .uk-container.uk-flex.uk-flex-between.uk-flex-middle
            router-link.logo.logo--header(:to="{name: 'home'}")
                img(src="@/assets/images/svg/flex.svg" alt="" width="155")
            .header__spacer
            .header__registration.uk-flex(v-if="!hideButtons" )
                //router-link.link__register.link__register--pink.uk-text-uppercase(v-if="!isTrial" :to="{name: 'RegisterFree'}" @click.native="activateGoal('butregistr');") 14 дней бесплатно
                router-link.link__register.uk-text-uppercase(:to="{name: 'home'}" @click.native="activateGoal('butregistr');") Регистрация
                router-link.link__register.uk-text-uppercase(:to="{name: 'Login'}")  Авторизация
            router-link.header__autorisation(:to="{name: 'home'}" v-if="!hideButtons" )
                svg
                    use(xlink:href="#autorisation")
            //.header__burger(@click="$bus.$emit('menu.open', true)")
            //    span
</template>

<script>
import { activateGoal } from "@/helpers/metric";

export default {
  name: "v-header",

  props: {
    hideButtons: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isTrial: false
  }),
  created() {
    this.$nextTick(() => {
      this.isTrial = sessionStorage.getItem("tariff.trial") === "true";
    });
  },
  methods: {
    activateGoal
  }
};
</script>
