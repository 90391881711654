import axios from "@/mixins/axios";

export const mutations = {
    set: (state, { results, next }) => {
        state.items = results;
        state.next = next;
    },

    append: (state, { results, next }) => {
        state.next = next;
        state.items.map((x, index) => {
            if (x.page === state.page) {
                results[x.index]
                    ? state.items.splice(index, 1, results[x.index])
                    : state.items.splice(index);
            }
        });
    },

    preload: state => {
        state.page += 1;
        state.items = [
            ...state.items,
            ...Array.from({ length: state.limit }, (i, index) => ({
                page: state.page,
                index,
                preload: true
            }))
        ];
    },

    clear: state => (state.items = []),

    current: (state, payload) => (state.current = payload),

    loaded: (state, payload = true) => (state.loaded = payload),

    remove: (state, payload) => {
        if (state.items.length > 0) {
            const index = state.items.findIndex(x => x.id === payload);
            if (~index) {
                state.items.splice(index, 1);
            }
        }
    }
};

export const actions = {
    async init({ commit, state: { apiAction, items, loaded } }) {
        if (items.length > 0 && loaded) return;
        commit("preload");
        const { data } = await axios.get(apiAction);
        if (data) {
            commit("current", apiAction);
            commit("set", data);
        }
        commit("loaded");
    },

    async nextPage({ commit, state }) {
        if (!state.next || state.wait) return;
        state.loading = true;
        state.wait = true;
        commit("preload");
        const { data } = await axios.get(state.next);
        commit("current", state.next);
        state.wait = false;
        commit("append", data);
        state.loading = false;
    }
};
