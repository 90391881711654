import { emailRegex, phoneRegex } from "@/helpers/regex";
import { getCleanPhoneNumber } from "@/helpers/common";
import { activateGoal } from "@/helpers/metric";
// TODO: might be improve by using routes collection and mapped by Register view component
const registerComponentRoutes = ["other", "home"];

const defaultForm = {
    phone: "",
    email: "",
    password: ""
};

export default {
    data: () => ({
        form: {
            ...defaultForm
        },
        errors: {},
        isPhoneRegister: true,
        buttonDisabled: false,
        layout: "registration",
        repeatTime: 0,
        repeatInterval: null,
        code: "",
        hideSocials: false,
        layoutConfirm: false,
        recaptchaToken: null,
    }),

    computed: {
        confirmText() {
            return this.isPhoneRegister
                ? `на указанный номер придет смс с кодом для продолжения регистрации, для этого нажмите на кнопку - "Получить смс" и введите полученный код в открывшемся поле "Код подтверждения"`
                : "Введите проверочный код, который мы вам выслали на почту указанную вами при регистрации.";
        },
        confirmButtonText() {
            return "Выслать код повторно";
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
        isRegistrationFlow() { return this.$route.meta.isRegistrationFlow; },
        savedForm() {
            const flowForm = this.isRegistrationFlow ? "registerForm" : "loginForm";
            return this.$store.state[flowForm];
        }
    },

    watch: {
        isPhoneRegister() {
            this.setStateForm();
        }
    },

    created() {
        this.form = { ...this.savedForm };
        const {
            isPhoneRegister,
            repeatTime,
            repeatInterval
        } = this.$store.state.formState;
        this.isPhoneRegister = isPhoneRegister;
        this.repeatTime = repeatTime;
        this.repeatInterval = repeatInterval;
    },

    methods: {
        enableButton() {
            this.buttonDisabled = false;
        },

        disableButton() {
            this.buttonDisabled = true;
        },

        async register() {
            this.errors = {};
            this.disableButton();
            this.hideSocials = true;

            const form = {
                password: this.form.password.trim()
            };

            if (this.isPhoneRegister) {
                form.phone = this.form.phone.trim();
            } else {
                form.email = this.form.email.trim();
            }

            // Client validation
            if (!this.validateFields(true, form)) {
                this.buttonDisabled = false;
                return;
            }

            await this.$axios
                .post("/api/v4/auth/validate-userdata/", form)
                .catch(this.prepareErrors)
                .finally(this.enableButton);
            await this.$router.push({ name: "RegisterConfirm" });
        },

        validateFields(password, form) {
            if (!this.isPhoneRegister && !emailRegex.test(form.email)) {
                this.errors.email = ["Введите корректный адрес электронной почты"];
            }
            if (this.isPhoneRegister && !phoneRegex.test(form.phone)) {
                this.errors.phone = ["Введите корректный номер телефона"];
            }

            if (password) {
                const passwordErrors = this.passwordValidation(form.password);
                if (passwordErrors.length > 0) this.errors.password = passwordErrors;
            }

            for (const key in form) {
                if (
                    form[key] !== form.email &&
                    form[key] !== form.phone &&
                    !form[key]
                ) {
                    this.errors[key] = ["Поле не может быть пустым"];
                }
            }

            return !(Object.keys(this.errors).length > 0);
        },

        prepareErrors(maybeResponseErrors) {
            const UNHANDLED_ERROR = { non_field_errors: "Ошибка на сервере!" };
            if (!maybeResponseErrors?.response?.data) {
                this.errors = UNHANDLED_ERROR;
                return;
            }

            const errors = maybeResponseErrors.response.data;

            if (typeof errors === "string") {
                this.errors = UNHANDLED_ERROR;
                return;
            }

            if (errors.detail) {
                this.errors = { non_field_errors: [errors.detail] };
                return;
            }

            this.errors = errors;
        },

        runRepeat() {
            if (this.repeatInterval) clearInterval(this.repeatInterval);

            this.repeatTime = 180;
            this.repeatInterval = setInterval(() => {
                this.repeatTime -= 1;
                if (this.repeatTime <= 0) {
                    clearInterval(this.repeatInterval);
                }
            }, 1000);
        },

        repeatCode() {
            this.sendCode();
        },

        async sendCode(token) {
            this.errors = {};
            this.buttonDisabled = true;

            const payload = this.isPhoneRegister
                ? { phone: getCleanPhoneNumber(this.form.phone) }
                : this.prepareData();

            payload.recaptcha = token;

            this.$axios
                .post(`/api/v4/auth/send-code/`, payload)
                .then(this.runRepeat)
                .catch(this.prepareErrors)
                .finally(this.enableButton);
        },

        checkCode() {
            this.errors = {};
            this.buttonDisabled = true;

            const form = {
                password1: this.form.password.trim(),
                code: this.code
                    .trim()
                    .split(" ")
                    .join("")
            };
            if (this.isPhoneRegister) {
                form.phone = this.form.phone.trim();
            } else {
                form.email = this.form.email.trim();
            }
            if (this.isDomainKg) form.country = 'KG';

            const referer = sessionStorage.getItem("referer");
            if (referer) {
                form.referer = referer;
                sessionStorage.removeItem("referer");
            }

            this.$axios
                .post(`/api/v4/auth/registration/`, form)
                .then(() => {
                    this.layoutConfirm = true;

                    try {
                        VK.Goal("complete_registration");
                        dataLayer.push({
                            event: "GAEvent",
                            eventCategory: "registration",
                            eventAction: "form"
                        });
                        activateGoal("confirm");
                    } catch (_) {}
                })
                .catch(this.prepareErrors)
                .finally(this.enableButton);
        },

        prepareData() {
            const data = {
                reset: false
            };

            if (this.isPhoneRegister) {
                data.phone = getCleanPhoneNumber(this.form.phone);
            } else {
                data.email = this.form.email;
            }
            if (this.isDomainKg) data.country = 'KG';

            return data;
        },

        login() {
            try {
                dataLayer.push({
                    event: "GAEvent",
                    eventCategory: "choose_tariff",
                    eventAction: "click"
                });
                ym(72658729, "reachGoal", "click_choose_tariff");
            } catch (_) {}
            this.buttonDisabled = true;

            const form = {
                password: this.form.password.trim()
            };
            if (this.isPhoneRegister) {
                form.phone = this.form.phone.trim();
            } else {
                form.email = this.form.email.trim();
            }

            this.$axios
                .post("/api/v4/login/", form)
                .then(({ data: userInfo }) => {
                    localStorage.setItem("token", userInfo.token);
                    localStorage.setItem("refresh_token", userInfo.refresh_token);
                    this.$axios.defaults.headers.common[
                        "Authorization"
                    ] = `JWT ${userInfo.token}`;
                    activateGoal("login");

                    const subscriptionIsActive =
            userInfo.user.subscription?.is_active ?? false;
                    const hasObtainedFilms = userInfo.user?.has_obtained_films;

                    if (!subscriptionIsActive && !hasObtainedFilms) {
                        sessionStorage.setItem("to.link", "subscriptions");
                    }

                    this.$bus.$emit("user.auth");
                    this.$router.push({ name: "home" }).catch(() => {});
                })
                .catch(this.prepareErrors)
                .finally(this.enableButton);
        },

        nextStep(path) {
            this.errors = {};
            this.buttonDisabled = true;
            this.hideSocials = true;

            const form = {};

            if (this.isPhoneRegister) {
                form.phone = this.form.phone.trim();
            } else {
                form.email = this.form.email.trim();
            }
            // Client validation
            if (!this.validateFields(false, form)) {
                this.buttonDisabled = false;
                return;
            }

            const mutation = this.isRegistrationFlow ? "SET_REGISTER_FORM" : "SET_LOGIN_FORM";
            this.$store.commit(mutation, this.form);
            this.$store.commit("SET_STATE_FORM", {
                isPhoneRegister: this.isPhoneRegister,
                repeatTime: this.repeatTime,
                repeatInterval: this.repeatInterval,
                registerRoute: this.$route.name
            });

            // Check login and if ok, go to next step
            if (!path) {
                return this.$axios
                    .post("/api/v4/auth/validate-userdata/", form)
                    .then(() => {
                        activateGoal("registrbtn");
                        this.$router.push({ name: "RegisterPassword" });
                    })
                    .catch(this.prepareErrors)
                    .finally(this.enableButton);
            }
            this.$router.push({ name: path });
        },

        setStateForm() {
            this.$store.commit("SET_STATE_FORM", {
                isPhoneRegister: this.isPhoneRegister,
                repeatTime: this.repeatTime,
                repeatInterval: this.repeatInterval
            });
        },

        setAuthForm() {
            const mutation = this.isRegistrationFlow ? "SET_REGISTER_FORM" : "SET_LOGIN_FORM";
            this.$store.commit(mutation, { ...this.form });
        },

        getRecapToken(token) {
            this.recaptchaToken = token;
        }
    },

    beforeRouteLeave(_, __, next) {
        this.setAuthForm();
        this.setStateForm();
        next();
    }
};
