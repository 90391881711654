import axios from "@/mixins/axios";
const API_URL = "/api/v4/films/";

import {
    mutations as listMutations,
    actions as listActions
} from "@/helpers/list-api";

export default {
    namespaced: true,
    state: {
        page: 1,
        limit: 12,
        next: null,
        wait: false,
        ranges: [],
        filters: {
            genres: [],
            country_original: [],
            years: []
        },
        items: [],
        apiAction: API_URL,
        loading: true,
        metaData: {
            meta: [],
            pageTitle: "",
            metaTitle: "",
            bottom_text: ""
        },
        snowTitleAffix: false
    },
    mutations: {
        ...listMutations,

        resetFilter: state => {
            state.items = [];
            state.loading = true;
        },

        loaded: state => (state.loading = false),

        setFilters: (state, filter) => {
            state.filters = filter;
            state.filters.years = [];

            for (let i = +filter.year_from; i <= +filter.year_to; i++) {
                state.filters.years.push(i);
            }
        },

        meta: (state, { countries, genres, years }) => {
            const meta = [];
            let pageTitle = "",
                metaTitle = "",
                metaDesc = "",
                bottom_text = "";

            state.snowTitleAffix = false;

            // Генерация тайтлов и мета данных
            // Если только один жанр в адресе
            if (genres.length === 1 && countries.length === 0) {
                /* if genres[0].header NOT falsy('', null, false, 0) value */
                pageTitle = genres[0].header
                    ? genres[0].header
                    : `Сериалы в жанре:  ${genres[0].name}`;
                metaDesc =
          genres[0].description ||
          `✔Смотрите онлайн бесплатно! ${genres[0].name} в хорошем качестве. Всего 999 сериалов! Лучшие подборки только на SerialClub 👍`;
                metaTitle = `Сериалы в жанре: ${genres[0].name} - смотреть онлайн бесплатно. Список лучших сериалов в хорошем качестве | SerialClub`;
                bottom_text = genres[0].bottom_text;
            }
            // Если только одна страна в адресе
            else if (genres.length === 0 && countries.length === 1) {
                pageTitle = countries[0].header
                    ? countries[0].header
                    : `Сериалы, страна:  ${countries[0].name}`;
                metaDesc =
          countries[0].description ||
          `✔Смотрите онлайн бесплатно! ${countries[0].name} в хорошем качестве. Всего 999 сериалов! Лучшие подборки только на SerialClub 👍`;
                metaTitle = `Сериалы, страна:  ${countries[0].name} - смотреть онлайн бесплатно. Список лучших сериалов в хорошем качестве | SerialClub`;
                bottom_text = countries[0].bottom_text;
            } else if (!genres.length && !countries.length) {
                metaDesc = ``;
                metaTitle = `Фильтр сериалов`;
                pageTitle = `Фильтр сериалов`;
                state.snowTitleAffix = true;
            }
            // Если в адресе больше двух сегментов любого типа
            else {
                metaDesc = `Подборка сериалов от SerialClub`;
                metaTitle = `Подборка сериалов от SerialClub`;
                pageTitle = `Подборка сериалов от SerialClub`;
                meta.push({
                    hid: "robots",
                    property: "robots",
                    content: "noindex, nofollow"
                });
            }

            // Получение полного тайтла и делаем первую букву заглавной
            pageTitle =
        pageTitle
            .trim()
            .charAt(0)
            .toUpperCase() + pageTitle.trim().substr(1);

            // Получение Meta тайтла и делаем первую букву заглавной
            metaTitle =
        metaTitle
            .trim()
            .charAt(0)
            .toUpperCase() + metaTitle.trim().substr(1);

            // Добавлние метадескрипшена
            meta.push({ hid: "description", name: "description", content: metaDesc });

            state.metaData = {
                meta,
                pageTitle,
                metaTitle,
                bottom_text
            };
        }
    },
    actions: {
        ...listActions,

        async init({ commit, state: { apiAction, items } }, data) {
            commit("preload");
            const { data: results } = await axios.get(apiAction, {
                params: {
                    ...data,
                    genres: data.genres.join(","),
                    country_original: data.country_original.join(",")
                }
            });
            commit("set", results);
            commit("loaded");
        },

        async load({ commit }) {
            const { data: data } = await axios.get("/api/v4/films/filters/");
            commit("setFilters", data);
        },

        async search({ commit, state }, data) {
            state.wait = false;
            data.page = 1;
            state.loading = true;
            if (data.likes) {
                data.ordering = "-like";
            }

            const { data: result } = await axios.get(API_URL, {
                params: {
                    ...data,
                    genres: data.genres.join(","),
                    country_original: data.country_original.join(",")
                }
            });
            commit("set", result);
            commit("loaded");
        }
    }
};
