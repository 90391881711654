<template>
  <div class="footer__policy-links">
    <a
      v-for="link in $options.FOOTER_MENU_CENTER"
      :key="link.to"
      @click.prevent="
        $bus.$emit(
          'change.layout',
          'terms',
          `api/v4/terms${link.to}/`,
          link.name
        )
      "
    >
      {{ link.name }}
    </a>
  </div>
</template>

<script>
import { FOOTER_MENU_CENTER } from "@/constants/menu-footer";
export default {
  FOOTER_MENU_CENTER
};
</script>

<style lang="scss" scoped>
.footer__policy-links {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  a {
    padding: 5px;
    color: #b4b9c0;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: #b4b9c0;
    }
  }
}
</style>
