<template>
  <div class="zenit" v-if="content">
    <div class="uk-container">
      <div class="zenit__grid">
        <div class="zenit__data">
          <div class="zenit__title">{{ content.title }}</div>
          <div class="zenit__desc">
            <div v-html="content.text"></div>
            <div v-html="content.text_for_adaptive"></div>
          </div>
          <div class="zenit__logo" v-if="showZenit">
            <img src="@/assets/images/zenit/zenit-logo-white.svg" alt="" />
          </div>
        </div>
        <div class="zenit__image">
          <img :src="content.image" alt="" />
          <img :src="content.image_for_adaptive" alt="" />
        </div>
      </div>

      <button
        type="button"
        class="button button--header uk-flex uk-flex-center uk-flex-middle"
        @click="openUrl"
      >
        ЗАБРАТЬ БОНУС
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZBanner",

  data: () => ({
    content: null,
    showZenit: false
  }),

  methods: {
    openUrl() {
      window.open(this.content.link, "_blank");
    }
  },

  created() {
    this.$axios.get("/api/v3/info/four_showcase/").then(({ data }) => {
      if (data.results.length) {
        this.content = data.results[0];
      }
    });
  }
};
</script>

<style lang="scss">
.zenit {
  background: url("../assets/images/zenit/bg.png") no-repeat center;
  padding: 114px 0 45px 0;

  @media all and (max-width: 968px) {
    padding: 45px 0 45px 0;
  }

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 478px;
    margin-bottom: 48px;
    grid-gap: 10px;
    @media all and (max-width: 968px) {
      grid-template-columns: 1fr;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 48px;

    @media all and (max-width: 968px) {
      font-size: 24px;
    }
    @media all and (max-width: 550px) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  &__desc {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: #b4b9c0;
    margin-bottom: 56px;
    max-width: 500px;

    @media all and (max-width: 968px) {
      font-size: 18px;
      max-width: none;
    }
    @media all and (max-width: 550px) {
      font-size: 14px;
      margin-bottom: 16px;
      max-width: 60%;
    }

    div {
      &:last-child {
        display: none;
      }

      @media all and (max-width: 968px) {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }

  &__data {
    align-self: center;
    position: relative;
    z-index: 3;
  }

  &__image {
    @media all and (max-width: 968px) {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      width: 200px;
    }

    img {
      &:last-child {
        display: none;
      }

      @media all and (max-width: 1280px) {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }

  &__logo {
    max-width: 247px;

    img {
      width: 100%;
    }
  }
}
</style>
