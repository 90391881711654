/* eslint-disable */
const numbersRegex = /[0-9]+/;
const lettersRegex = /[a-zA-Z]+/g;
const whitespaceRegex = /\s/;
const unicodeRegex = /[^\u0000-\u007F]+/g;
/* eslint-enable */

export default {
    methods: {
    // Пароли передаются параметрами, потому что данные не единообразны
        passwordValidation(password) {
            const errors = [];
            if (password.length < 8) {
                errors.push("Минимальная длина пароля должна быть 8 знаков.");
            }

            if (!numbersRegex.test(password)) {
                errors.push("Пароль должен содержать цифры.");
            }

            lettersRegex.lastIndex = unicodeRegex.lastIndex = 0;
            if (!lettersRegex.test(password) || unicodeRegex.test(password)) {
                errors.push(
                    "Пароль должен содержать латинские буквы, без кириллицы. Можно использовать символы."
                );
            }

            if (whitespaceRegex.test(password)) {
                errors.push("Пароль должен быть без пробелов.");
            }

            return errors;
        }
    }
};
