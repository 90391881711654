export default {
    namespaced: true,
    state: {
        data: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: []
        },
        list: []
    },

    mutations: {
        create: (state, payload) => {
            state.list = [];
            state.data.itemListElement = [];

            let position = 1;
            state.data.itemListElement.push({
                "@type": "ListItem",
                position,
                item: {
                    "@id": `${payload.host}`,
                    name: "Главная"
                }
            });

            state.list.push({
                link: "/",
                name: "Главная"
            });

            payload.items.forEach(item => {
                position += 1;
                state.data.itemListElement.push({
                    "@type": "ListItem",
                    position,
                    item: {
                        "@id": `${payload.host}${item.link}`,
                        name: item.name
                    }
                });

                state.list.push(item);
            });
        }
    }
};
