import axios from "@/mixins/axios";
export default {
    namespaced: true,
    state: {
        links: {
            tg_channel_link: "https://t.me/kinoflex_tv",
            vk_group_link: "https://vk.com/kinoflex_tv",
            android_app_link: "media/Flex.apk",
            android_tv_app_link: "media/FlexTV.apk",
            tizen_app_link: "tizen-manual/Flex.wgt",
            tg_support_link: "https://t.me/flex_supportbot"
        }
    },

    mutations: {
        SET_LINKS: (state, payload) => {
            state.links = payload;
        }
    },
    actions: {
        getLinks({ commit }) {
            axios
                .get("/api/v4/site-info/")
                .then(result => {
                    const { data } = result;
                    commit("SET_LINKS", data);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
};
