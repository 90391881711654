<template>
  <div>
    <template v-if="type === 'login'">
      Нажимая на кнопку «Войти»,
      вы принимаете условия
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/user/',
            'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
          )
        "
        >Пользовательского соглашения</span
      >
      и даёте согласие на
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/private-policy/',
            'Политика конфиденциальности'.toUpperCase()
          )
        "
      >
        обработку персональных данных,</span>
        подтверждаете, что ознакомились с информацией и достигли совершеннолетия (18+).
        <span @click.prevent="$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/agreement-18/',
          'Соглашение 18+'
        )">Подробнее</span>
    </template>
    <template v-if="type === 'register'">
      Нажимая на кнопку «Зарегистрироваться» или «Войти», вы принимаете условия
      <br />
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/user/',
            'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
          )
        "
      >
        Пользовательского соглашения
      </span>
      и даёте согласие на
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/private-policy/',
            'Политика конфиденциальности'.toUpperCase()
          )
        "
        >обработку персональных данных,</span
      >
      подтверждаете, что ознакомились с информацией и вам 18+.
      <span @click.prevent="$bus.$emit(
        'change.layout',
        'terms',
        'api/v4/terms/agreement-18/',
        'Соглашение 18+'
      )">
        Подробнее
      </span>
    </template>
    <template v-if="type === 'free'">
      Нажимая на кнопку «Попробовать бесплатно» <br />
      вы принимаете условия <br />
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/user/',
            'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
          )
        "
        >Пользовательского соглашения</span
      >
      и даёте согласие на
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/private-policy/',
            'Политика конфиденциальности'.toUpperCase()
          )
        "
        >обработку персональных данных.</span
      >
    </template>
    <template v-if="type === 'register-password'">
      Нажимая на кнопку «Зарегистрироваться» <br />
      вы принимаете условия <br />
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/user/',
            'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
          )
        "
        >Пользовательского соглашения</span
      >
      и даёте согласие на
      <span
        @click.prevent="
          $bus.$emit(
            'change.layout',
            'terms',
            'api/v4/terms/private-policy/',
            'Политика конфиденциальности'.toUpperCase()
          )
        "
      >
        обработку персональных данных,
      </span>
      подтверждаете, что ознакомились с информацией и достигли совершеннолетия (18+).
      <span @click.prevent="$bus.$emit(
        'change.layout',
        'terms',
        'api/v4/terms/agreement-18/',
        'Соглашение 18+'
      )">
        Подробнее
      </span>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "register"
    }
  }
};
</script>
