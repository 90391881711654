export default {
    data: () => ({
        recaptchaToken: null,
    }),
    filters: {
        plurals: (n, titles, append = true) =>
            (append ? n + " " : "") +
      titles[
          n % 10 === 1 && n % 100 !== 11
              ? 0
              : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
                  ? 1
                  : 2
      ]
    },
    methods: {
        checkDomain(url) {
            const filter = ["kg", "kz"];
            return filter.some(el => url.includes(el));
        },
    },
    computed: {
        isDomainKg() {
            if (this.checkDomain(location?.host)) {
                this.isPhoneRegister = false;
                return true;
            }
        },
        recaptchaKey() {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
        },
        recaptchaSettings() {
            return {
                sitekey: this.recaptchaKey,
                version: 2,
                mode: 'enterprise',
                loadRecaptchaScript: true,
                hideBadge: true,
                size: 'invisible',
            };
        }
    }
};

