<template>
  <div class="container uk-container">
    <Pathway v-if="!hidePathway" />
    <div class="container__head" v-if="title">
      <div class="container__title">{{ title }}</div>
      <slot name="head" />
    </div>
    <slot />
  </div>
</template>

<script>
import Pathway from "@/components/Pathway";

export default {
  name: "container",
  components: { Pathway },
  props: {
    title: {
      type: String,
      default: null
    },

    hidePathway: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;

  @media (max-width: 1700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 880px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2em;
    color: #ffffff;
    align-self: center;

    @media (max-width: 340px) {
      font-size: 22px;
    }
  }

  &--title-small-margin & {
    &__title {
      margin-bottom: 0;
    }
  }

  &__head {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 40px;
  }
}
</style>
