<template>
  <a class="application application--footer application--apple" :href="link">
    <img src="@/assets/images/svg/apple.svg" alt="" v-if="icon === 'apple'" />
    <img src="@/assets/images/pdf.png" alt="" v-else-if="icon === 'pdf'" />
    <img src="@/assets/images/cloud.png" alt="" v-else-if="icon === 'tizen'" />
    <img src="@/assets/images/svg/android.svg" alt="" v-else />
    <div class="application__text">
      {{ title || "Скачать для" }}<br /><span>{{ platform }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: "download",
  props: {
    icon: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    platform: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss">
.application {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-gap: 16px;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

  &--apple {
    svg {
      width: 22px;
      min-width: 22px;
      height: 27px;
    }
  }

  &--apple-tv {
    svg {
      width: 52px;
      min-width: 52px;
      height: 27px;
    }
  }

  &--android {
    svg {
      width: 20px;
      min-width: 20px;
      height: 24px;
    }
  }

  &--android-tv {
    svg {
      width: 50px;
      min-width: 50px;
      height: 24px;
    }
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: normal;
    color: #ffffff;

    span {
      font-weight: bold;
    }
  }

  &--footer {
    width: 100%;
    max-width: 168px;
    border-radius: 8px;
    background-color: #122337;
    height: 50px;
    padding: 0 10px;
  }
}
</style>
