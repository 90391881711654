<template lang="pug">
Modal
    template(v-if="layout === 'registration'")
        .mainbar__title Регистрация
        .register__closed(v-if="lockEmailRegister") Регистрация через email недоступна по техническим причинам, мы уже работаем над устранением этой проблемы
        form.register.uk-flex.uk-flex-column(@submit.prevent="register")
            ul.register__through.uk-flex.uk-flex-between.uk-flex-bottom(data-uk-tab)
                li.uk-active
                    a(@click="isPhoneRegister = true") По номеру телефона
                li(:class="{'uk-disabled': lockEmailRegister}")
                    a(@click="isPhoneRegister = false") По E-mail
            InputField.register__field(
                v-if="isPhoneRegister"
                type="tel"
                icon="phone"
                placeholder="Номер телефона"
                v-model="form.phone"
                :disabled="buttonDisabled"
                autocomplete="tel"
            )
            InputField.register__field(
                v-else
                type="email"
                icon="mail"
                placeholder="E-mail"
                v-model="form.email"
                :disabled="buttonDisabled"
                autocomplete="email"
            )
            InputField.register__field(
                type="text"
                icon="user"
                placeholder="Никнейм"
                v-model="form.username"
                :disabled="buttonDisabled"
                autocomplete="username"
            )
            InputField.register__field(
                type="password"
                icon="lock"
                placeholder="Пароль"
                v-model="form.password1"
                :disabled="buttonDisabled"
                autocomplete="new-password"
            )
            InputField.register__field(
                type="password"
                icon="lock"
                placeholder="Повторите пароль"
                v-model="form.password2"
                :disabled="buttonDisabled"
                autocomplete="new-password"
            )

            Errors.uk-margin-bottom(
                :errors="errors"
                v-if="Object.keys(errors).length"
            )

            button.register__btn(
                type="submit"
                :disabled="buttonDisabled"
            ) Регистрация

    template(v-if="layout === 'call'")
        .mainbar__title Регистрация
        form.confirmation.uk-flex.uk-flex-column
            p.confirmation__title Осталось немного...
            p.confirmation__text На указанный номер придет смс с кодом для продолжения регистрации, для этого нажмите на кнопку - "Получить смс" и введите полученный код в открывшемся поле "Код подтверждения"
            Errors.uk-margin-bottom(
                :errors="errors"
                v-if="Object.keys(errors).length"
            )
            button.confirmation__button(@click.prevent="sendCode") Получить смс

    template(v-if="layout === 'confirm-code'")
        .mainbar__title Регистрация
        form.register.uk-flex.uk-flex-column(@submit.prevent="checkCode")
            p.confirmation__title Осталось немного...
            p.confirmation__text {{ confirmText }}
            InputField.confirmation__field(
                type="text"
                placeholder="Код подтверждения"
                v-model="code"
                icon="mail-modal"
                mod="modal"
                max-length="4"
            )

            Errors.uk-margin-bottom(
                :errors="errors"
                v-if="Object.keys(errors).length"
            )

            button.confirmation__button(
                type='submit'
                :disabled="buttonDisabled"
            ) Подтвердить

            button.confirmation__button.confirmation__button--resend(
                @click="repeatCode"
                type='button'
                :disabled = "repeatTime > 0"
            ) {{ confirmButtonText }}

            p.confirmation__repeat-text(v-if="repeatTime > 0") Повторная отправка возможна через {{ repeatTime | plurals(['секунду', 'секунды', 'секунд']) }}

    template(v-if="layout === 'complete'")
        .confirmation.confirmation--done.uk-flex.uk-flex-column
            svg.confirmation__icon
                use(xlink:href='#done')
            p.confirmation__title Спасибо за регистрацию!
            p.confirmation__text.confirmation__text--done Вы успешно зарегистрировались! Осталось выбрать подходящий тариф.
            button.confirmation__button(@click="login") Выбрать тариф

    template(v-if="layout==='registration'")
        Agreement
        .uk-text-center
            button.register__account-btn(@click="$emit('change', 'auth')") Есть аккаунт? Войти
        ContactUs(:policyVisible="false")
</template>

<script>
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import Landing from "@/components/Modal";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";
import Modal from "@/components/Modal";
import Agreement from "@/components/Agreement";
import ContactUs from "@/components/ContactUs";

export default {
  name: "register",

  components: {
    ContactUs,
    Agreement,
    InputField,
    Landing,
    Errors,
    Modal
  },

  data: () => ({
    lockEmailRegister: false
  }),

  mixins: [validatePassword, RegisterMixins, utils],

  props: {
    isPromoRight: {
      type: Boolean,
      default: true
    }
  }
};
</script>
