<template lang="pug">
    .profile__container
        .profile.uk-flex.uk-flex-middle.uk-position-relative
            .profile__avatar(@click="dropdown = !dropdown")
                img(
                    v-if="user.avatar"
                    :src="user.avatar"
                    alt=""
                )
                img(src="@/assets/images/profile.png" alt="" v-else)
            .profile__name {{user.username}}

            .profile__select(style="cursor: pointer;" @click="dropdown = !dropdown")
                img(src="@/assets/images/drop_arrow.png" alt="")
            .profile-menu(v-if="dropdown")
                .profile-menu__user.uk-text-nowrap.uk-text-left Баланс: {{(user.balance / 100).toLocaleString("ru-RU", {style: "currency",currency: "RUB"}) }}
                .profile-menu__user.uk-text-nowrap.uk-text-left(v-if="user.phone") {{user.phone | phone}}
                .profile-menu__user.uk-text-nowrap.uk-text-left(v-else) {{user.email}}
                button.register__btn.register__btn--small.uk-text-nowrap(
                    type="button"
                    style="font-size: 13px;"
                    @click="$bus.$emit('auth.logout')"
                ) Выйти
</template>

<script>
export default {
  name: "profile",

  props: {
    user: {
      type: Object
    }
  },

  data: () => ({
    dropdown: false
  }),

  methods: {
    logout() {
      this.dropdown = false;
      this.$bus.$emit("auth.logout");
    }
  },

  mounted() {
    document.querySelector(".page").addEventListener("click", e => {
      if (!e.target.closest(".profile")) {
        this.dropdown = false;
      }
    });
  },

  filters: {
    phone: val =>
      val
        .trim()
        .split(" ")
        .join("")
        .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4")
  }
};
</script>
