import { emailRegex, phoneRegex } from "@/helpers/regex";
import { getCleanPhoneNumber } from "@/helpers/common";

export default {
    data: () => ({
        login: "",
        password: "",
        buttonDisabled: false,
        errors: {},
        errorLabelMap: {
            non_field_errors: "Ошибка",
            phone: "Телефон",
            email: "E-mail",
            password: "Пароль"
        }
    }),

    methods: {
        submitForm() {
            this.buttonDisabled = true;
            this.errors = {};

            if (!this.login.trim().length) {
                this.errors.login = ["Это поле не может быть пустым"];
            }

            if (!this.password.length) {
                this.errors.password = ["Это поле не может быть пустым"];
            }

            if (Object.keys(this.errors).length > 0) {
                this.buttonDisabled = false;
                return;
            }

            const form = {};
            const login = this.login.trim();
            form.password = this.password;

            if (emailRegex.test(login)) {
                form.email = login;
            } else if (phoneRegex.test(login)) {
                form.phone = getCleanPhoneNumber(login);
            } else {
                form.username = login;
            }

            this.$axios
                .post("api/v4/login/", form)
                .then(({ data }) => {
                    try {
                        dataLayer.push({
                            event: "GAEvent",
                            eventCategory: "authorization",
                            eventAction: "form"
                        });
                        ym(72658729, "reachGoal", "authorization");
                    } catch (_) {}
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("refresh_token", data.refresh_token);
                    this.$axios.defaults.headers.common[
                        "Authorization"
                    ] = `JWT ${data.token}`;
                    this.$bus.$emit("user.auth", "Enter");
                    this.$router.push({ name: "home" }).catch(() => {});
                })
                .catch(({ response }) => {
                    if (typeof response.data === "string") {
                        this.errors = {
                            non_field_errors: "Возникла непредвиденная ошибка"
                        };
                    } else {
                        this.errors = response.data;
                    }

                    this.buttonDisabled = false;
                });
        }
    }
};
