<template lang="pug">
    .input-toggle
        input.input-toggle__input(type="checkbox" id="input-toggle" v-model="toggle")
        label.input-toggle__label(for="input-toggle") Toggle
</template>

<script>
export default {
  name: "InputToggle",
  data: () => ({
    toggle: false
  }),
  watch: {
    toggle(newVal) {
      this.$emit("toggle", newVal ? "tel" : "text");
    }
  }
};
</script>
