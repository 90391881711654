<template lang="pug">
    ul.form__errors
        li(v-for="(error, i) in listErrors" :key="i") {{ error }}
</template>

<script>
export default {
  name: "errors",
  props: {
    errors: {}
  },

  data: () => ({
    errorLabelMap: {
      non_field_errors: "Ошибка",
      login: "Логин",
      phone: "Телефон",
      email: "E-mail",
      username: "Никнейм",
      password: "Пароль",
      password1: "Пароль",
      password2: "Повторение пароля",
      code: "Проверочный код",
      promocode: "Промокод"
    }
  }),

  computed: {
    listErrors() {
      const errors = [];

      if (this.errors instanceof Object) {
        for (const key in this.errors) {
          if (this.errors[key] instanceof Array) {
            this.errors[key].forEach(error => {
              errors.push((this.errorLabelMap[key] || "Ошибка") + ": " + error);
            });
          } else {
            errors.push(
              (this.errorLabelMap[key] || "Ошибка") + ": " + this.errors[key]
            );
          }
        }
      }

      return errors;
    }
  }
};
</script>
