<template lang="pug">     .auth-page
        header.serials-page__header
            HeaderBase
            HeaderMenu
        main.auth-page__container
            AuthWrapper(:hide-description="true")
                template(#custom-label) Придумайте пароль для входа на любом <br/> устройстве
                form(@submit.prevent="register")
                    InputField.register__field(
                        type="password"
                        icon="lock"
                        placeholder="Пароль"
                        v-model="form.password"
                        :disabled="buttonDisabled"
                        autocomplete="new-password"
                    )
                    Errors.uk-margin-bottom(v-if="hasErrors" :errors="errors")
                    .register__button-container.register__button-container--back
                        button.register__btn-back(type="button" @click="goBack")
                        button.register__btn(type="submit" :disabled="buttonDisabled") Зарегистрироваться
                template(#footer)
                    Agreement(type="register-password")


</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import AuthWrapper from "@/views/auth/components/AuthWrapper";
import Agreement from "@/views/auth/components/Agreement";
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";

export default {
  name: "RegisterPassword",

  components: {
    AuthWrapper,
    Agreement,
    HeaderBase,
    HeaderMenu,
    InputField,
    Errors
  },
  mixins: [validatePassword, RegisterMixins, utils],
  data: () => ({
    lockEmailRegister: false
  }),
  created() {
    if (!this.form.email && !this.form.phone) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    goBack() {
      this.$router.push({name: this.$store.state.formState.registerRoute || 'home'});
    }
  }
};
</script>
