<template lang="pug">
Modal(no-close)
    template(v-if="loading")
        .uk-text-center
            img(src="~@/assets/images/loader.svg" alt)
    template(v-else)
        .confirmation.confirmation--done.uk-flex.uk-flex-column(v-if="!freeTariff")
            svg.confirmation__icon
                use(xlink:href='#done')
            p.confirmation__title(style="color: white;") Вы успешно приобрели тариф!
            p.confirmation__text Теперь функционал FLEX доступен вам на 100%. Приятного просмотра 🙂
            button.confirmation__button(@click="$bus.$emit('user.auth')") Смотреть
        .confirmation.confirmation--done.uk-flex.uk-flex-column(v-else)
            svg.confirmation__icon
                use(xlink:href='#done')
            p.confirmation__title(style="color: white;") Поздравляем! Вы успешно активировали тариф
            p.confirmation__text Теперь вам доступны сотни сериалов в профессиональной озвучке TVShows.
            p.confirmation__text Приятного просмотра!
            button.confirmation__button(@click="$bus.$emit('user.auth')") Смотреть
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "PaySuccess",

  components: {
    Modal
  },

  data: () => ({
    loading: true,
    freeTariff: false
  }),

  created() {
    this.loading = true;
    this.$axios
      .get("api/v3/user/")
      .then(({ data }) => {
        if (data.subscription.tariff_payment_active) {
          try {
            ym(72658729, "reachGoal", "purchase");
            dataLayer.push({
              event: "GAEvent",
              eventCategory: "purchase",
              eventAction: "lead"
            });
            VK.Goal("purchase");
          } catch (_) {}
          this.freeTariff = true;
          this.$router.push({ name: "complete" });
        }
        this.loading = false;
      })
      .catch(() => {});
  }
};
</script>
