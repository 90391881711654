<template lang="pug">
label.field(:class="inputClasses")
    template(v-if="type === 'tel'")
        TheMask(
            class="field__input"
            mask="(###)-###-####"
            :placeholder="placeholder || 'Ваш номер телефона'"
            ref="field"
            :disabled="disabled"
            @input="changeTel"
            :value="value"
            name="identifier"
        )
    template(v-else)
        input.field__input(
            :type="type === 'password' && !isPasswordHidden ?  'text' : type"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="value"
            :autocomplete="autocomplete"
            @input="change"
            @focus="isFocus = true"
            @blur="isFocus = false"
            ref="field"
        )
    template(v-if="icon")
        .field__icon(v-if="icon === 'tel'") +7
        svg.field__icon(
            v-else-if="icon"
            :class="`field__icon--${icon}`"
        )
            use(:xlink:href="`#${icon}`")
    button.field__toggle-btn.uk-flex.uk-flex-middle.uk-flex-center(
        type="button"
        tabindex="-1"
        v-if="type === 'password'"
        @click.prevent="isPasswordHidden = !isPasswordHidden"
    )
        svg(:class="{close: !isPasswordHidden}")
            use(:xlink:href="`#eye-${!isPasswordHidden ? 'hide' : 'open'}`")
</template>

<script>
import InputMixins from "@/mixins/input";
import { TheMask } from "vue-the-mask";

export default {
  name: "field-box",

  mixins: [InputMixins],

  components: {
    TheMask
  },

  props: {
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    mod: {
      type: String,
      default: ""
    },
    isFieldRight: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    maxLength: {
      default: false
    }
  },

  methods: {
    changeTel(value) {
      this.$emit("input", value);
    }
  }
};
</script>
