<template lang="pug">
Terms
    template(v-slot:title) ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
    template
        .terms__content(v-html="content")
</template>

<script>
import Modal from "@/components/Modal";
import Terms from "@/components/Terms";

export default {
  name: "terms",

  components: {
    Modal,
    Terms
  },

  data: () => ({
    content: ""
  }),

  created() {
    this.$axios.get("api/v4/terms/user/").then(({ data }) => {
      this.content = data.text.replace(/{domain}/g, location.hostname);
    });
  }
};
</script>
