<template lang="pug">
.more
    .title.title--header.title--space Смотрите то, что хочется. Тысячи сериалов и фильмов в одном месте.
    .desc.uk-flex.uk-flex-center.uk-text-center В профессиональной озвучке. В лучшем качестве. На любом устройстве.
    button.button.uk-flex.uk-flex-center.uk-flex-middle(type="submit" @click.prevent="redirectRegister") ЗАРЕГИСТРИРОВАТЬСЯ
    .link__more.uk-flex.uk-flex-center.uk-flex-column(
        v-scroll-to="{ element: '.serials', duration: 500, offset: -50 }")
        img(src="@/assets/images/svg/arrow-down.svg" alt="")

</template>

<script>
import { activateGoal } from "@/helpers/metric";

export default {
  name: "more",
  methods: {
    redirectRegister() {
      activateGoal("butregistr");
      this.$router.push({ name: "Register" });
    }
  }
};
</script>
