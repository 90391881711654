<template lang="pug">
.uk-container
    .title.title--serials.uk-flex.uk-text-center Смотрите везде, где хочется. На любом устройстве
    .see.see--turn
        .see__item
            .title__see На смартфонах и планшетах
            .desc.desc--see Установите приложение FLEX, чтобы смотреть сериалы где угодно.
            .link__pay.uk-flex
                //Download.uk-margin-right(link="https://testflight.apple.com/join/2g7cQfvC" icon="apple" platform="iOS")
                //Download(link="/media/Flex.apk" icon="android" platform="Android")
            .title__see На ТВ-приставках
            .desc.desc--see Наслаждайтесь контентом на большом экране, воспользовавшись ТВ-приставкой
            .link__pay.uk-flex.uk-flex-middle
                //Download.uk-margin-right(link="https://testflight.apple.com/join/2g7cQfvC" icon="apple" platform="iOS TV")
                //Download(link="/media/FlexTV.apk" icon="android" platform="Android TV")
        .see__image
            img(src="@/assets/images/devices.png" alt="" class="uk-visible@s")

        button.button.button--tv.uk-flex.uk-flex-center.uk-flex-middle.see__button(type="button" @click="redirectRegister") Оформить подписку

</template>

<script>
import Download from "@/components/Download";
import { activateGoal } from "@/helpers/metric";
export default {
  name: "see",
  components: { Download },
  methods: {
    redirectRegister() {
      activateGoal("butregistr");
      this.$router.push({ name: "Register" });
    }
  }
};
</script>
