<template>
  <div :style="{ display: 'flex', gridColumn: tariff.is_trial ? '1/-1' : '1' }">
    <ModalWrapper
      v-if="payModalIsVisible"
      @onClose="payModalIsVisible = false"
      class="custom-modal--middle"
    >
      <PayVariants
        v-if="status === 'form'"
        @payByCard="onPayByCard"
        @payByBalance="onPayByBalance"
      >
        <template #description>Выберите удобный способ покупки тарифа</template>
        <template #errors>
          <Errors :errors="errors" v-if="errors" class="form__errors--large" />
        </template>
      </PayVariants>
      <TariffPayComplete
        v-if="status === 'complete'"
        @complete="handleOnComplete"
      />
      <div class="pay-loader" v-if="disabled">
        <Preloading />
      </div>
    </ModalWrapper>

    <form :action="payForm.url" method="POST" v-if="payForm" ref="payForm">
      <input
        type="hidden"
        v-for="(value, field) in payForm.content"
        :key="field"
        :name="field"
        :value="value"
      />
    </form>

    <VButton
      class="v-button--default v-button--active"
      :disabled="disabled"
      @click="handleOnPay"
      style="background:  #FF0070; flex: 1"
    >
      {{ tariff.is_trial ? "ПОПРОБОВАТЬ БЕСПЛАТНО" : "ОФОРМИТЬ ПОДПИСКУ" }}
    </VButton>
  </div>
</template>

<script>
import ModalWrapper from "@/components/Modal/ModalWrapper";
import VButton from "@/components/Form/VButton";
import Errors from "@/components/Errors";
import PayVariants from "@/components/Modal/PayVariants";
import Preloading from "@/components/List/Preloading";
import TariffPayComplete from "@/components/Modal/TariffPayComplete";
import {
  addCart,
  addBuying,
  saveTariffPayload,
  activateGoal
} from "@/helpers/metric";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "modal-tariff-pay",

  components: {
    TariffPayComplete,
    Preloading,
    PayVariants,
    Errors,
    VButton,
    ModalWrapper
  },

  props: {
    tariff: {
      type: Object,
      default: () => ({})
    },
    promo: {
      type: String,
      default: null
    }
  },

  data: () => ({
    payModalIsVisible: false,
    disabled: false,
    errors: null,
    payForm: null,
    status: "form"
  }),

  computed: {
    ...mapGetters(["authorized"])
  },

  methods: {
    ...mapActions(["reFetch"]),

    prepareFormData() {
      let data = {
        id: this.tariff.id,
        scenario: "fake",
        domain: location.origin + "/",
        force: true,
        reuse_card: false,
        is_reccurent: false
      };

      if (this.tariff.payment.indexOf("fake") === -1) {
        data.scenario = this.tariff.payment[0];
      }

      if (this.promo !== null) {
        data.coupon = this.promo;
      }

      return data;
    },

    onPayByCard() {
      this.disabled = true;
      this.errors = null;
      try {
        VK.Goal("subscribe");
        ym(72658729, "reachGoal", "click_subscribe");
        addCart({ tariff: this.tariff });
      } catch (_) {}
      this.$axios
        .post("api/v4/payment/start/", this.prepareFormData())
        .then(({ data: { content, url, type, purchase: id } }) => {
          try {
            if (this.tariff.is_trial) {
              addBuying({ tariff: this.tariff, id: null });
              activateGoal("aktivtesttarif");
            }
          } catch {}
          sessionStorage.removeItem("tariff.trial");
          if (id) {
            saveTariffPayload({ tariff: this.tariff, id, coupon: this.promo });
          }
          if (type === "form") {
            this.payForm = {
              url,
              content
            };
            this.$nextTick(() => {
              this.$refs.payForm.submit();
            });
          } else if (type === "redirect") {
            window.location = url;
          }
        })
        .catch(({ response }) => {
          this.errors = response.data;
          alert("Возникла ошибка, попробуйте соврешить оплату позже!");
          this.disabled = false;
        })
        .finally(() => {
          this.payModalIsVisible = false;
        });
    },

    onPayByBalance() {
      this.errors = null;
      this.disabled = true;
      addCart({ tariff: this.tariff });
      this.$axios
        .post("api/v4/balance/buy-subscription/", this.prepareFormData())
        .then(({ data: { purchase: id } }) => {
          sessionStorage.removeItem("tariff.trial");
          try {
            if (this.tariff.is_trial) {
              addBuying({ tariff: this.tariff, id, coupon: this.promo });
              activateGoal("aktivpaidtarif");
            }
            VK.Goal("purchase");
          } finally {
            console.log("ecommerce purchase send", id);
          }
          this.reFetch();
          this.status = "complete";
        })
        .catch(({ response }) => {
          this.errors = response.data;
        })
        .finally(() => {
          this.disabled = false;
        });
    },

    handleOnComplete() {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refresh_token");
      let append = "";

      if (sessionStorage.hasOwnProperty("to.link")) {
        append = "?to=" + sessionStorage.getItem("to.link");
        sessionStorage.removeItem("to.link");
      }

      window.location.href = `/loading/?token=${token}&refresh=${refreshToken}&append=${append}`;
    },

    handleOnPay() {
      if (!this.authorized) {
        activateGoal("butregistr");
        this.$router.push({
          name: this.tariff.is_trial ? "RegisterFree" : "Register"
        });
        return;
      }
      if (this.tariff.is_trial) {
        activateGoal("testtarif");
        this.onPayByCard();
      } else {
        activateGoal("tarifkoplate");
        this.payModalIsVisible = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  background: rgba(0, 0, 0, 0.6);
}
</style>
