<template lang="pug">
    .auth-page
        header.serials-page__header
            HeaderBase
            HeaderMenu
        main.auth-page__container
            AuthWrapper(:hide-description="true")
                Reset

</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import AuthWrapper from "@/views/auth/components/AuthWrapper";
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";
import resetMixin from "@/mixins/reset";
import Reset from "@/components/Reset";

export default {
  name: "ResetView",

  components: {
    Reset,
    AuthWrapper,
    HeaderBase,
    HeaderMenu,
    InputField,
    Errors
  },
  mixins: [validatePassword, RegisterMixins, utils, resetMixin],
  data: () => ({
    lockEmailRegister: false,
    login: '',
  })
};
</script>
