<template lang="pug">
    .menu.menu__burger
        transition(name="fade" appear)
            .menu__overlay(v-if="toggleMenu" @click.self.prevent="toggleAction(false)")
        transition(name="menu-slide")
            .menu__body(v-if="toggleMenu")
                .menu__head
                    .menu__close-button(@click.self.prevent="toggleAction(false)")
                //router-link.menu__link.menu__link--pink(v-if="!isTrial" :to="{name: 'RegisterFree'}" @click.native="activateGoal('butregistr')") 14 дней бесплатно
                router-link.menu__link(:to="{name: 'home'}") Главная
                router-link.menu__link(:to="{name: 'serials'}") Сериалы
                router-link.menu__link(:to="{name: 'films'}") Фильмы
                router-link.menu__link(:to="{name: 'tariffs'}") Тарифы
                router-link.menu__link(:to="{name: 'FAQ'}") FAQ
</template>
<script>
import { activateGoal } from "@/helpers/metric";
export default {
  data: () => ({
    toggleMenu: false,
    isTrial: false
  }),
  created() {
    this.$nextTick(() => {
      this.isTrial = sessionStorage.getItem("tariff.trial") === "true";
    });
  },
  mounted() {
    this.$bus.$on("menu.open", status => {
      this.toggleMenu = status;
      document.body.classList.add("fix-modal");
    });
  },
  methods: {
    activateGoal,
    toggleAction(status) {
      this.$bus.$emit("menu.open", status);
      if (status === false) document.body.classList.remove("fix-modal");
    }
  },
  destroyed() {
    document.body.classList.remove("fix-modal");
  }
};
</script>
