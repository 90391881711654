import axios from "@/mixins/axios";
const API_URL = "/api/v4/films/";

import {
    mutations as listMutations,
    actions as listActions
} from "@/helpers/list-api";

export default {
    namespaced: true,
    state: {
        page: 1,
        limit: 12,
        next: null,
        wait: false,
        ranges: [],
        filters: {
            genres: [],
            country_original: [],
            years: []
        },
        items: [],
        apiAction: API_URL,
        loading: true
    },
    mutations: {
        ...listMutations,
        loaded: state => (state.loading = false)
    },
    actions: {
        ...listActions,

        async init({ commit, state: { apiAction, items } }, data) {
            commit("preload");
            const { data: results } = await axios.get(apiAction, {
                params: {
                    ...data
                }
            });
            commit("set", results);
            commit("loaded");
        }
    }
};
