<template lang="pug">
.genres
    .title.title--serials Не только новинки,<br /> но и культовая классика
    .desc.desc--genres.uk-text-center Сериалы «Друзья», «Санта Барбара» и другие, также на нашем сервисе
    .genres__image.uk-flex.uk-flex-center.uk-text-center
    .uk-container
        button.button.button--genres.uk-flex.uk-flex-center.uk-flex-middle(type="button" @click="$bus.$emit('change.layout', 'register')") ПОНОСТАЛЬГИРОВАТЬ

</template>

<script>
export default {
  name: "genres"
};
</script>
