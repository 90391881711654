<template>
  <div class="custom-modal">
    <div class="custom-modal__overlay" @click="$emit('onClose')"></div>
    <div class="custom-modal__wrapper">
      <div class="custom-modal__body">
        <button
          class="custom-modal__close"
          type="button"
          @click="$emit('onClose')"
        >
          <svg>
            <use xlink:href="#close"></use>
          </svg>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-wrapper"
};
</script>

<style lang="scss">
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(5, 23, 44, 0.8);
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 20px;
    overflow-y: auto;
    display: flex;
  }

  &__body {
    position: relative;
    width: 100%;
    max-width: 680px;
    padding: 76px;
    border-radius: 8px;
    border: solid 2px #122337;
    background: #041223;
    margin: auto;

    @media all and (max-width: 479px) {
      padding: 24px;
    }
  }

  &__close {
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;

    svg {
      stroke: #838ca4;
      width: 24px;
      height: 24px;
    }
  }

  &--serial & {
    &__body {
      padding: 30px;
    }
  }

  &--middle & {
    &__body {
      padding: 36px;
    }
  }

  &--large & {
    &__body {
      max-width: 980px;
      padding: 36px;
    }
  }
}
</style>
