<template lang="pug">
Modal(no-close)
    template
        .confirmation.confirmation--done.uk-flex.uk-flex-column
            p.confirmation__title(style="color: white;") Упс... Во время покупки произошла ошибка
            p.confirmation__text Попробуйте ещё раз или напишите в нашу техподдержку на support@kinoflex.tv, и мы обязательно со всем разберёмся
            button.confirmation__button(@click="tryAgain") повторить
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "PayFail",

  components: {
    Modal
  },

  methods: {
    tryAgain() {
      this.$bus.$emit("user.auth");
      this.$nextTick(() => {
        this.$router.push({ name: "home" });
      });
    }
  }
};
</script>
