export default {
    data: () => ({
        isPasswordHidden: true,
        isFocus: false
    }),

    computed: {
        inputClasses: vm => ({
            "field--tel": vm.type === "tel",
            "field--modal-password": vm.mod === "modal-password",
            "field--password": vm.type === "password",
            "field--modal": vm.mod === "modal",
            "field--promo": vm.mod === "promo",
            focus: vm.isFocus
        })
    },

    methods: {
        change(e) {
            let value = e.target.value;
            if (this.maxLength) {
                value = value
                    .trim()
                    .split(" ")
                    .join("")
                    .replace(/[^\d]+/g, "");
                if (value.length > this.maxLength)
                    value = value.slice(0, this.maxLength);
                e.target.value = value.split("").join(" ");
                value = e.target.value;
            }
            this.$emit("input", value);
        }
    }
};
