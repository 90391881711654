<template lang="pug">
    Modal.modal--tizen(bodyClass="uk-flex")
        .mainbar__title Установка приложения FLEX на телевизоры под управлением Tizen OS
        .mainbar__downloads.uk-flex.uk-flex-row.uk-flex-around
            Download(:link="links.links.tizen_app_link" title="Скачать файл" icon="tizen" platform="Tizen")
            Download(link="/tizen-manual/Инструкция_Tizen.pdf" icon="pdf" title="Инструкция в" platform="PDF")
</template>

<script>
import Modal from "@/components/Modal";
import Download from "@/components/Download";
import { mapState } from "vuex";
export default {
  name: "Tizen",
  components: {
    Modal,
    Download
  },
  computed: mapState(["links"])
};
</script>
