<template lang="pug">
    .auth-page
        header.serials-page__header
            HeaderBase
            HeaderMenu
        main.auth-page__container
            AuthWrapper(:login="true" :hide-description="true")
                template(#custom-label) Введите пароль для входа в аккаунт
                form(@submit.prevent="login")
                    InputField.register__field(
                        type="password"
                        icon="lock"
                        placeholder="Пароль"
                        v-model="form.password"
                        :disabled="buttonDisabled"
                        autocomplete="new-password"
                    )
                    Errors.uk-margin-bottom(
                        :errors="errors"
                        v-if="Object.keys(errors).length"
                    )
                    .register__button-container.register__button-container--back(style="margin-bottom: 108px;")
                        button.register__btn-back(
                            type="button"
                            @click="$router.push({name: 'Login'})"
                        )
                        button.register__btn(
                            type="submit"
                            :disabled="buttonDisabled"
                        ) Войти
                template(#footer) Нажимая на кнопку «Войти» <br/> вы принимаете условия <br/> <span>Пользовательского соглашения</span> и даёте согласие на <span>обработку персональных данных.</span>


</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import AuthWrapper from "@/views/auth/components/AuthWrapper";
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";

export default {
  name: "RegisterPassword",

  components: {
    AuthWrapper,
    HeaderBase,
    HeaderMenu,
    InputField,
    Errors
  },
  mixins: [validatePassword, RegisterMixins, utils],
  data: () => ({
    lockEmailRegister: false
  })
};
</script>
