<template lang="pug">
.serials(data-uk-slider="center: true")

    .uk-slider-container.serials__container.uk-position-relative
        ul.uk-slider-items.serials__items
            li.serials__item(
                v-for="(image, index) in items"
                :key="index"
            )
                router-link(:to="{name: image.is_film? 'film-slug' : 'serial-slug', params: {slug: image.slug}}")
                    img(:src="image.poster_mini" alt="" style="width: 100%")
        a.uk-position-center-right.uk-hidden-hover.uk-slider-arrow(href="#" uk-slider-item="next")
        a.uk-position-center-left.uk-hidden-hover.uk-slider-arrow(href="#" uk-slider-item="previous")
    .title.title--serials Здесь вы найдете практически все новинки кино и сериалов!
    .desc.desc--genres.uk-flex.uk-flex-center.uk-text-center FLEX собрал самые громкие и актуальные проекты от лучших мировых студий и сервисов.
    .serials__buttons.uk-container.uk-flex
        button.button.button--header.uk-flex.uk-flex-center.uk-flex-middle(type="button" @click.prevent="$router.push({name: 'serials'})") Каталог сериалов
        button.button.button--header.uk-flex.uk-flex-center.uk-flex-middle(type="button" @click.prevent="$router.push({name: 'films'})") Каталог фильмов

</template>

<script>
export default {
  name: "serials",

  data: () => ({
    items: []
  }),
  created() {
    this.$axios.get("/api/v4/banner-landing/").then(res => {
      this.items = res.data?.results;
    });
  }
};
</script>
