import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import UIkit from "uikit";
import http from "@/mixins/axios";
import router from "@/router";
import VueScrollTo from "vue-scrollto";
import VueHead from "vue-head";
import VueMeta from "vue-meta";
import Cookie from "cookie-universal";

Vue.use(VueHead);
Vue.use(VueMeta);
Vue.prototype.$cookies = Cookie();
Vue.use(VueScrollTo);
Vue.prototype.$uikit = UIkit;
Vue.config.productionTip = false;

Vue.prototype.$axios = http;
Vue.prototype.$bus = new Vue();

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
