<template lang="pug">
.uk-container
    .title.title--serials Загрузите ваш сериал, чтобы смотреть в автономном режиме
    .desc.desc--genres.uk-text-center Сохраните ваши любимые сериалы в мобильном приложении и смотрите когда удобно
    .loading
        .loading__item.uk-flex.uk-flex-between.uk-flex-middle
            .loading__images
                img(src="@/assets/images/multfilmy_0.png" alt="")
            .loading__information
                .loading__original Rick and Morty
                .loading__name Рик и Морти
                .uk-flex
                    .loading__original 2020
                    .loading__original США
                    .loading__original Мультик
            .loading__wrapper
            a.loading__progress(href="javascript:;" @click.prevent="$bus.$emit('change.layout', 'register')")
                img(src="@/assets/images/progress.png" alt="")

        .loading__item.uk-flex.uk-flex-between.uk-flex-middle
            .loading__images
                img(src="@/assets/images/items/item9.jpg" alt="")
            .loading__information
                .loading__original Fearless
                .loading__name Бесстрашная
                .uk-flex
                    .loading__original 2017
                    .loading__original Детектив
            .loading__wrapper
            a.loading__progress(href="javascript:;" @click.prevent="$bus.$emit('change.layout', 'register')")
                img(src="@/assets/images/progress-ok.png" alt="")
    button.button.button--loading.uk-flex.uk-flex-center.uk-flex-middle(type="button" @click="$bus.$emit('change.layout', 'register')") Попробовать

</template>

<script>
export default {
  name: "loading"
};
</script>
